/* eslint-disable no-unused-vars */
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/es5/locale/pt';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify, {});

const theme = {
  primary: '#5C6BC0',
  secondary: '#4dd0e1',
  accent: '#e91e63',
  info: '#00CAE3',
  success: '#1de9b6',
  warning: '#FB8C00',
  error: '#FF5252',
};

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },
  theme: {
      light: theme,
  },
});
