<template>
  <Card toolbar dark color="primary">
    <h3 slot="toolbar">Notas Fiscais NFC-e</h3>

    <template>
      <DataTable
        no-sync
        no-search
        :headers="headers"
        :loading="loading"
        :items="invoices"
        :hide-default-footer="true"
        :page="page"
        :per-page="per_page"
        :total-pages.sync="totalPages"
        @reloadPageInfo="setPageInfo"
        @reloadListData="getInvoices" >

        <template v-slot:actions="props">
          <Button type-icon :icon="$icons.printerPos" color="primary" @click="printInvoice(props.props.item)" />
          <Button type-icon :icon="$icons.note" color="green" @click="transmitInvoice(props.props.item)" />
          <Button type-icon :icon="$icons.refresh" color="success" @click="consultInvoice(props.props.item)" />
          <Button type-icon :icon="$icons.cancel" color="red" @click="$emit('cancel', props.props.item)" />
        </template>
      </DataTable>
    </template>

    <template slot="actions">
      <Button label="(Esc) Fechar" color="error" v-shortkey="['esc']" @shortkey="$emit('toggleModalList', 'close')" @click="$emit('toggleModalList', 'close')" />
    </template>
  </Card>
</template>

<script>
import Card from '@/components/vuetify/Card';
import Button from '@/components/vuetify/Button';
import DataTable from '@/components/vuetify/DataTable';
import { messageErrors } from '@/utils';

export default {
  name: 'Invoices',
  components: { Card, Button, DataTable },
  props: {},
  data () {
    return {
      invoices: [],
      loading: false,
      page: 1,
      per_page: 10,
      totalPages: 0,
    };
  },
  async mounted() {
    this.getInvoices();
  },
  computed: {
    headers () {
      return this.$schemas.storeFront.invoicesHeaders;
    }
  },
  methods: {
    setPageInfo(payload){
      this.page = payload.page;
      this.per_page = payload.per_page;
    },
    async getInvoices() {
      this.loading = true;
      await this.$api.storeFront.invoices(this.page, this.per_page).then((res) =>  {
        this.invoices = res.data;
        console.log(this.invoices);
        this.totalPages = parseInt(res.paginator.total_pages);
        this.loading = false;
      }).catch((err) =>  {
        console.log(err);
        this.loading = false;
      });
    },
    async consultInvoice(invoice) {
      try {
        const invoiceConsult = await this.$api.storeFront.invoiceConsult( invoice.id ).then(() => {
          if (invoiceConsult.status === 'authorized') {
            const cnpjEmitter = invoiceConsult.emitter.cnpj.replaceAll('.','').replaceAll('/','').replaceAll('-','');
            const urlPrint = `${process.env.VUE_APP_BUCKET_NFE_URL}/${cnpjEmitter}/pdf/${invoiceConsult.invoice_key}.pdf`;
            const fileName = `NFCe${invoiceConsult.invoice_key}.pdf`;
            this.$downloadFile(urlPrint, fileName);
          }
        });
        
      } catch (err) {
        console.log(err);
        if(err.response?.status === 422) {
          this.$noty.error(messageErrors(err.response));
        } else {
          this.$noty.error('Erro desconhecido, tente novamente!');
        }
      }
    },
    async transmitInvoice(invoice) {
      try {
        await this.$api.storeFront.invoiceTransmit(invoice.id);
        const invoiceShow = await this.$api.storeFront.invoiceShow( invoice.id );
        if (invoiceShow.status === 'authorized') {
          const cnpjEmitter = invoiceShow.emitter.cnpj.replaceAll('.','').replaceAll('/','').replaceAll('-','');
          const urlPrint = `${process.env.VUE_APP_BUCKET_NFE_URL}/${cnpjEmitter}/pdf/${invoiceShow.invoice_key}.pdf`;
          const fileName = `NFCe${invoiceShow.invoice_key}.pdf`;
          this.$downloadFile(urlPrint, fileName);
        }
      } catch (err) {
        console.log(err);
        if(err.response?.status === 422) {
          this.$noty.error(messageErrors(err.response));
        } else {
          this.$noty.error('Erro desconhecido, tente novamente!');
        }
      }
    },
    async printInvoice(invoice) {
      try {
        const invoiceShow = await this.$api.storeFront.invoiceShow( invoice.id );
        if (invoiceShow.status === 'authorized') {
          const cnpjEmitter = invoiceShow.emitter.cnpj.replaceAll('.','').replaceAll('/','').replaceAll('-','');
          const urlPrint = `${process.env.VUE_APP_BUCKET_NFE_URL}/${cnpjEmitter}/pdf/${invoiceShow.invoice_key}.pdf`;
          const fileName = `NFCe${invoiceShow.invoice_key}`;
          this.$downloadFile(urlPrint, fileName);
        }
      } catch (err) {
        console.log(err);
        if(err.response?.status === 422) {
          this.$noty.error(messageErrors(err.response));
        } else {
          this.$noty.error('Erro desconhecido, tente novamente!');
        }
      }
    }
  }
};
</script>

<style>

</style>