export default {
  domain: 'auth',
  routes: {
    login: {
      name: 'login',
      path: '/login',
    },
    authRedirect: {
      name: 'auth-redirect',
      path: '/auth/redirect',
    },
  },
};