export  default {
  registerItem: {
    title: 'Cadastrar item na venda',
    infosCurrentItem: {
      product: 'Produto',
      unitary_value: 'Valor unitário',
      qtd: 'Qtd.',
      amount: 'Valor Total',
    },
    actions: {
      cancel: 'Cancelar',
      remove: 'Remover Item',
      findItem: 'Pesquisar item',
      finish: 'Finalizar Venda',
    }
  },
  contentActions:  {
    title: 'Atalhos'
  },
  orderSales: {
    Cancel: 'Pedido cancelado com sucesso!' 
  }
};