<template>
  <Card toolbar dark color="primary">
    <h3 slot="toolbar">Digite CPF/CNPJ do Cliente</h3>

    <template>
      <TextFieldSimpleMask :mask="[masks.cpf,masks.cnpj]" autofocus rounded ref="inputBarCode" v-model="cpf" label="CPF/CNPJ" @keyup.enter="$emit('setCPF', cpf)" />
    </template>

    <template slot="actions">
      <Button label="(Enter) Selecionar" v-shortkey="['enter']" color="success" @shortkey="$emit('setCPF', cpf)" @click="$emit('setCPF', cpf)" />
      <Button label="(Esc) Cancelar" color="red" dark v-shortkey="['esc']" @shortkey="$emit('toggleModalList', 'close')" @click="$emit('toggleModalList', 'close')" />
    </template>
  </Card>
</template>

<script>
import Card from '@/components/vuetify/Card';
import TextFieldSimpleMask from '@/components/vuetify/TextFieldSimpleMask';
import Button from '@/components/vuetify/Button';
import { cpf, cnpj } from '@/utils/masks';

export default {
  name: 'CustomerCPF',
  components: { Card, TextFieldSimpleMask, Button },
  data() {
    return {
      cpf: '',
      masks: { cpf, cnpj }
    };
  }
};
</script>

<style>

</style>