/* eslint-disable no-unused-vars */
import { axiosInstanceNF } from '@/service';
import { onlyNumbers } from '@/utils';

const NFCommands = () => ({
  async transmit(params) {
    const { sale } = params;
    const { company } = sale;
    const { items } = sale;
    const address = company.addresses[0];
    const addressBuyer = sale.buyer.addresses[0];

    const  payload = {
      config: {
        type_environment: process.env.VUE_APP_NF_ENVIRONMENT,
        social_reason: sale.company.name,
        cnpj: onlyNumbers(company.cnpj),
        uf: address.uf,
        csc: sale.invoice_settings.nfce.csc_token,
        csc_id: sale.invoice_settings.nfce.id_token
      },
      nature_operation_state: sale.nature_operation_state,
      ide: {
        code_uf: 41,
        code_nf: '00000777',
        nature_operation: 'VENDA',
        model: sale.invoice_settings.model,
        series: sale.invoice_settings.series,
        number_nf: parseInt(sale.invoice_settings.current_number) + 1,
        date_time_emission: Date.now(),//'2022-04-07T12:00:00-03:00',
        type_operation_nf: 1,
        identification_destiny_operation: 1,
        code_city_occurrence_generator: address.ibge,
        type_print: 4,
        type_emission: 1,
        verifying_digit: 1,
        type_environment: process.env.VUE_APP_NF_ENVIRONMENT,
        purpose_nfe: 1,
        final_consumer_operation: 1,
        indIntermed: null,
        presence_indicator: 1,
        process_emission: 0,
        process_version: 'iCertus ERP',
        contingency_date: null,
        justification: null
      },
      emitter: {
        name: company.name,
        social_name: company.social_name,
        ie: company.ie,
        tax_regime_code: 1,
        ie_tax_substitute: null,
        im: null,
        cnae: null,
        cnpj: onlyNumbers(company.cnpj),
        cpf: null,
        address: {
          code_country: address.country?.code,
          country: address.country?.name,
          zip_code: onlyNumbers(address.zip_code),
          uf: address.uf,
          code_city: address.ibge,
          city: address.city,
          neighborhood: address.neighborhood,
          street: address.street,
          number: address.number,
          complement: null,
          phone: null
        }
      },
      recipient: {
        name: sale.buyer.to_s,
        indIEDest: '9',
        ie: '',
        ISUF: '',
        im: '',
        email: sale.buyer.contacts[0].email,
        cnpj: sale.buyer.cnpj_cpf.length >= 14 ? sale.buyer.cnpj_cpf : '',
        cpf: sale.buyer.cnpj_cpf.length == 11 ? sale.buyer.cnpj_cpf : '',
        idEstrangeiro: null,
        address: {
          code_country: addressBuyer.country?.code,
          country: addressBuyer.country?.name,
          zip_code: addressBuyer.zip_code,
          uf: addressBuyer.uf,
          code_city: addressBuyer.ibge,
          city: addressBuyer.city,
          neighbourhood: addressBuyer.neighborhood,
          street: addressBuyer.street,
          number: addressBuyer.number,
          complement: addressBuyer.complement,
          phone: sale.buyer.contacts[0].email
        }
      },
      items: items.map((item) => {
        return {
          prod: {
            code_product: item.code,
            code_ean: item.barcode,
            code_ean_tax: item.barcode,
            description_product: item.to_s,
            cest: item.cest?.nickname || null,
            ncm: item.ncm?.nickname || '00000000',
            unity_commercial: 'UN',
            unity_tax: 'UN',
            quantity_commercial: item.qtd || 1,
            quantity_tax: item.qtd || 1,
            value_unity_commercial: item.sale_value,
            value_unity_tax: item.sale_value,
            value_product: item.sale_value,
            value_freight: 0,
            value_safe: 0,
            value_discount: 0,
            value_other: 0,
            indicate_value_item_total: 1,
          }
        };
      }),
      shipping: {
        modality_freight: 9
      },
      payment: {
        value_thing: '0.00',
        details: [
          {
            payment_method_indicator: '0',
            payment_method: '01',
            payment_method_description: 'Dinheiro',
            value_payment: sale.totalizers.final_value,
            cnpj_acquirer_band: null,
            band_operation_card: 99,
            number_authorization_card: null,
            payment_integration_type: '2'
          }
        ]
      },
      additional_information: {
        additional_information: ' ',
        complementary_information: ' '
      },
      responsible_technician: {
        cnpj: '17642368000156',
        contact: 'Robson Nascimento',
        email: 'robson@icertus.com.br',
        telephone: '4136536993'
      }
    };
    return await axiosInstanceNF.post('nfce/transmit', payload);
  },
  async getBarCode(params) {
    return new Promise((resolve, reject) => {
      axiosInstanceNF.post('nfce/get-bar-code', params).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    });
  },
});

export { NFCommands };