<template>
  <div />
</template>

<script>

export default {
  name: 'Login',
  async mounted() {
    const { token } = this.$route.query;
    await this.$api.authICertus.authRedirect(token);
  },
};
</script>
