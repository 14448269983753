<template>
  <v-app>
    <div style="height: 100%">
      <router-view></router-view>
    </div>
  </v-app>
</template>

<script>

export default {
  name: 'App',
};
</script>

<style scope>
.main {
  height: 100%;
  display: flex;
  width: 100%;
}
</style>