<template>
  <div class="grid grid-template-columns-1">
    <table width="100%">
      <tr>
        <td width="10%">
          <p class="mb-1 ml-3 header">Item</p>
        </td>
        <td width="20%">
          <p class="mb-1 header">Cod. Barras</p>
        </td>
        <td width="40%">
          <p class="mb-1 ml-12 header">Produto</p>
        </td>
        <td width="10%">
          <p class="mb-1 header">Qtd.</p>
        </td>
        <td width="10%">
          <p class="mb-1 header">Vlr. Unit.</p>
        </td>
        <td width="10%">
          <p class="mb-1 header">Vlr. Total</p>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ProductsListHeader'
};
</script>

<style>

</style>