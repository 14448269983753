/* eslint-disable no-unused-vars */
import axios from 'axios';
import DynamicService from '@/service/DynamicService';
import RegisterSchema from '../schemas/RegisterSchema';

const formatResponse = (item) => {
};

const RegistersService = DynamicService(RegisterSchema.domain, RegisterSchema, {
});

const RegisterCommands = () => ({
  async customers() {
    const payload = { q: { s: 'name asc', type_register_array: 'client' } };
    return await axios.post(`${RegisterSchema.domain}/filters`, payload);
  }
});

export default RegistersService;
export { RegistersService, RegisterCommands };