<template>
  <div />
</template>

<script>

export default {
  name: 'Login',
  mounted() {
    this.$api.authICertus.loginURL();
  },
};
</script>
