<template>
  <v-col :md="$attrs.md">
    <slot />
  </v-col>
</template>

<script>
export default {
  name: 'Col',
};
</script>
