<template>
  <Card toolbar dark color="primary">
    <h3 slot="toolbar">Clientes</h3>

    <template>
      <DataTable
        show-select
        single-select
        :headers="headers"
        :items="items" />
    </template>

    <template slot="actions">
      <Button label="Selecionar" color="primary" />
      <Button label="Cancelar" color="red" dark @click="$emit('toggleModalList', 'close')" />
    </template>
  </Card>
</template>

<script>
import Card from '@/components/vuetify/Card';
import DataTable from '@/components/vuetify/DataTable';
import Button from '@/components/vuetify/Button';

export default {
  name: 'ModalList',
  components: { Card, DataTable, Button },
  computed: {
    headers() {
      return [
        {
          text: 'Código',
          value: 'id'
        },
        {
          text: 'Nome',
          value: 'name'
        }
      ];
    },
    items() {
      return {
        total: 1,
        data: [
          {
            id: 1,
            name: 'Cliente 1'
          },
          {
            id: 2,
            name: 'Cliente 2'
          }
        ]
      };
    },
  }
};
</script>

<style>

</style>