/* eslint-disable no-unused-vars */
import { axiosInstanceICertus } from '@/service';
import { formatCurrency, getText, formatDatePT } from '@/utils';
import { statusInvoices } from '@/utils/options';
import { statusOrderSales } from '@/utils/options';

const formatResponseInvoice = (item) => {
  let payload = {
    ...item,
    status: getText(statusInvoices, item.status),
    totals_note: formatCurrency(item.total_note)
  };
  return payload;
};
const formatResponseInvoices = ({ data }) => {
  return data.map((item) => {
    return formatResponseInvoice(item);
  });
};

const formatResponseOrderSale = (item) => {
  return {
    id: item.id,
    created_at: item.created_at,
    sale_order: item.sale_order,
    note: item.note,
    orderDate: item.emission_date,
    exitDate: item.delivery_date,
    buyer: item.buyer,
    seller: {
      id: item.salesman.id,
      name: item.salesman.name
    },
    items: item.items,
    totalizers: {
      discount: item.total_discount,
      sub_total_value: item.total_products,
      total_value: item.total_products,
      final_value: item.total_ordered,
    },
    orderDate_formatted: formatDatePT(item.emission_date),
    status_formatted: getText(statusOrderSales, item.status),
    totals_order_formatted: formatCurrency(item.total_ordered)
  };
};

const formatResponseOrderSales = ({ data }) => {
  return data.map((item) => {
    return formatResponseOrderSale(item);
  });
};



const StoreFrontCommands = () => ({
  async graphqlProducts () {
    const payload = {
      query: '{ productsFilter (filter: {use_inactive_eq:false}) { collection { id toS name code barcode saleValue }}}'
    };
    return await axiosInstanceICertus.post('/graphql', payload);
  },
  async graphqlNatureOperations () {
    const payload = {
      query: '{ natureOperationsFilter (filter: {use_inactive_eq:false,type_nature_eq: nfce}) { collection { id name typeNature natureOperationStates {id ufState}}}}'
    };
    return await axiosInstanceICertus.post('/graphql', payload);
  },
  async graphqlStocks () {
    const payload = {
      query: '{ stocksFilter (filter: {use_inactive_eq:false,use_main_eq: true}) { collection { id toS useMain}}}'
    };
    return await axiosInstanceICertus.post('/graphql', payload);
  },
  async me () {
    return await axiosInstanceICertus.get('users/me.json');
  },
  async company (id) {
    return await axiosInstanceICertus.get(`companies/${id}.json`);
  },
  async invoiceSettings () {
    return await axiosInstanceICertus.get('/invoice_settings.json');
  },
  async updateInvoiceNumber (id, payload) {
    return await axiosInstanceICertus.put(`/invoice_settings/${id}.json`, payload);
  },
  async natureOperations () {
    return await axiosInstanceICertus.get('/nature_operations.json');
  },
  async natureOperationState (nature_operation_id, state_id) {
    return await axiosInstanceICertus.get(`/nature_operations/${nature_operation_id}/states/${state_id}.json`);
  },
  async stocks () {
    return await axiosInstanceICertus.get('/stocks.json');
  },
  async orderSales (page = 1, per_page = 10) {
    let data = [];
    let headers = {};
    let paginator = {};

    await axiosInstanceICertus.get(`/order_sales.json?page=${page}&per_page=${per_page}`).then((response)=>{
      headers = response.headers;
      
      paginator = {
        page: headers['icertusweb-current-page'],
        per_page: headers['icertusweb-items-per-page'],
        total_items: headers['icertusweb-total-items'],
        total_pages: headers['icertusweb-total-pages'],
      };

      response.data.forEach((item) => {
        data.push(formatResponseOrderSale(item));
      });
    });
    return { data, headers, paginator };
  },
  async customers () {
    const payload = { q: { s: 'name asc', type_register_array: 'client' } };
    return await axiosInstanceICertus.post('registers/filters.json', payload);
  },
  async buyer () {
    const payload = { q: { s: 'name asc', type_register_array: 'is_customer' } };
    return await axiosInstanceICertus.post('registers/filters.json', payload);
  },
  async getProductByBarCodeOrCode (search) {
    const payload = { q: { barcode_or_code_eq: `${ search }` } };
    return await axiosInstanceICertus.post('products/filters.json', payload);
  },
  async cancelSale (id) {
    return await axiosInstanceICertus.get(`/order_sales/${id}/cancel.json`);
  },
  async paymentMethods () {
    return await axiosInstanceICertus.get('all_types.json?type_all_type=payment_method&disable=false');
  },
  async invoiceShow (id) {
    const res = await axiosInstanceICertus.get(`/invoices/${id}.json`);
    return res.data;
  },
  async invoiceConsult (id) {
    const payload = {
      action_type: 'consult',
      justification: '',
      number: id
    };
    const res = await axiosInstanceICertus.post('/invoices/nfe/action.json', payload);
    return res.data;
  },
  async invoiceUpdate (invoice, cpf) {
    const payload = {
      ...invoice,
      buyer_attributes: {
        ...invoice.buyer,
        cnpj_cpf: cpf.replaceAll('.','').replaceAll('/','').replaceAll('-',''),
      }
    };
    const res = await axiosInstanceICertus.put(`/invoices/${invoice.id}.json`, payload);
    return res.data;
  },
  async invoices (page = 1, per_page = 10) {
    let data = [];
    let headers = {};
    let paginator = {};

    await axiosInstanceICertus.get(`/invoices/nfce/list_nfce.json?page=${page}&per_page=${per_page}`).then((response) => {
      headers = response.headers;
      
      paginator = {
        page: headers['icertusweb-current-page'],
        per_page: headers['icertusweb-items-per-page'],
        total_items: headers['icertusweb-total-items'],
        total_pages: headers['icertusweb-total-pages'],
      };

      response.data.forEach((item) => {
        data.push(formatResponseInvoice(item));
      });
    });
    return { data, headers, paginator };
  },
  async invoiceTransmit (params) {
    const  payload = {
      id: params
    };
    const res = await axiosInstanceICertus.post('/invoices/nfe/transmit.json', { invoices: [payload] });
    return res.data;
  },
  async generateSale (params) {
    const { sale } = params;
    const { items } = sale;
    const  payload = {
      emission_date: new Date().toISOString().substr(0, 10),
      freight: 'no_freight',
      note: '',
      origin: 'pdv',
      use_delivery: false,
      use_order_discount: false,
      status: 'delivered',
      use_forecast: false,
      use_freight: false,
      is_costumer: true,
      presence_indicator: 'in_person',
      nature_operation_id: sale.nature_operation_id,
      payment_method: [],
      payment_method_id: sale.payment_method_id,
      price_list: {
        id: null,
        to_s: ''
      },
      buyer_id: sale.buyer.id,
      salesman_id: null,
      customer: sale.customer,
      type_installment_id: null,
      shipping_company_id: null,
      delivery_date: new Date().toISOString().substr(0, 10),
      taxes_attributes: items.map((item) => {
        return {
          discount_value: '0.0',
          name: item.description,
          ncm_id: item.ncm.id,
          quantity: item.qtd || 1,
          total_value: item.amount,
          total_without_discount_value: item.amount,
          unit_cost_value: item.purchase_cost,
          unit_value: item.sale_value,
          nature_operation_id: item.nature_operation_id || sale.nature_operation_id,
          product_id: item.id,
          unity_id: item.unity_id,
          stock_id: sale.stock_id,
          cest_id: item.cest_id,
          total_value_tmp: item.sale_value,
        };
      }),
      export_attributes: null,
      items_attributes: items.map((item) => {
        return {
          discount_value: 0,
          freight_value: 0,
          another_value: 0,
          name: item.name,
          ncm_id: item.ncm.id,
          quantity: item.qtd || 1,
          total_value: item.amount,
          total_without_discount_value: item.amount,
          unit_cost_value: item.purchase_cost,
          unit_value: item.sale_value,
          comercial_value: item.sale_value,
          tributary_value: item.sale_value,
          nature_operation_id: item.nature_operation_id || sale.nature_operation_id,
          product_id: item.id,
          unity_id: item.unity_id,
          stock_id: sale.stock_id,
          cest_id: item.cest_id,
          total_value_tmp: item.sale_value,
          other_expenses: 0,
        };
      }),
      total_cost: 0,
      total_discount: 0,
      total_ordered: sale.totalizers.total_value,
      total_products: sale.totalizers.total_value,
      total_taxes: 0,
      stock_id: sale.stock_id,
      total_tax_approximates: 0,
      totalizers: {
        total_cofins: 0,
        total_icms: 0,
        total_icms_st: 0,
        total_icms_st_and_ipi: 0,
        total_ipi: 0,
        total_pis: 0,
        total_simple_national: 0,
    },
    };
    const res = await axiosInstanceICertus.post('/order_sales.json', payload);
    return formatResponseOrderSale(res.data);
  },
  async generateInvoice (params) {
    const { id, sale } = params;
    const  payload = {
      form_issuance: 'normal',
      purpose_issue: 'normal_nfe',
      danfe_format: 'danfe_nfce',
      document_type: 'exit',
      invoice_setting_id: sale.invoice_settings.id,
      model: sale.invoice_settings.model,
      series: sale.invoice_settings.series,
      invoice_number: parseInt(sale.invoice_settings.current_number) + 1,
      invoiceable_id: id,
      invoiceable_type: 'OrderSale',
      use_automatic: true,
      emission_date: '',
      exit_date: '',
      response_errors: [],
      contingency_attributes: {},
      request_key_attributes: {},
      response_protocol_attributes: {},
      service_attributes: {},
      tax_attributes: {},
      emitter_attributes: {},
      buyer_attributes: {},
      transporter_attributes: {},
      justificaion_attributes: {},
      bill_attributes: {
          duplicates_attributes: []
      },
      export_attributes: {},
      items_attributes: [],
      references_attributes: [],
      addresses_attributes: [],
      authorizers_attributes: [],
      fields_attributes: []
    };
    const res = await axiosInstanceICertus.post('/invoices.json', payload);
    return res.data;
  },
});

export { StoreFrontCommands };