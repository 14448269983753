<template>
  <v-text-field 
    v-bind="$attrs"
    v-mask="mask"
    v-on="$listeners"
    :ref="refs"
    :label="label" 
    :rules="rules"
    :dense="dense"
    :readonly="readonly" 
    :rounded="rounded"
    :autofocus="autofocus"
    :outlined="outlined"
    :disabled="disabled"
    :prepend-icon="icon"
     />
</template>


<script>
export default {
  name: 'TextFieldSimpleMask',
  computed: {
    value: {
      get() {
        return this.$attrs.value || '';
      },
      set() {
        
      }
    }
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    refs: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    mask: {
      type: [String, Array],
      default: '',
    },
    outputMask: {
      type: String,
      default: '',
    },
    applyAfter: {
      type: Boolean,
      default: false,
    },
    empty: {
      type: String,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    alphanumeric: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      default: '',
    },
  }
};
</script>

<style>

</style>