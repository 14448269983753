import { typePageOptions } from '@/utils';
import { text, address, list } from '@/utils/icons';
import { required, requiredSelectMultiple } from '@/utils/rules';
import { typeRegister, typePerson } from '@/utils/options';
import RegisterBusiness from '@/business/Register';

export default {
    domain: 'registers',
    business: RegisterBusiness,
    title: 'Cadastros',
    description: 'Lista de todos os cadastros no sistema',
    createBtn: 'Novo Cadastro',
    formAddress: true,
    itemsPerPage: 10,
    routes: {
      list: {
        name: 'registers',
        path: '/cadastros',
        meta: { typePage: typePageOptions.list, requiresAuth: true, }
      },
      create: {
        name: 'register-create',
        path: '/cadastros/novo',
        meta: { typePage: typePageOptions.create, requiresAuth: true, }
      },
      show: {
        name: 'register-show',
        path: '/cadastros/:id',
        meta: { typePage: typePageOptions.show, requiresAuth: true, }
      }
    },
    listActions: {
      has: true,
      noShow: false,
      noDestroy: false,
      situation: true,
      more: {
        has: true,
        items: []
      },
    },
    filters: {
      has: true,
      items: [
        {
          type: 'select',
          label: 'Tipo de Cadastro',
          name: 'type',
          icon: list,
          md: 6,
          items: {
            data: typeRegister
          }
        },
        {
          type: 'text',
          label: 'Nome',
          name: 'name',
          icon: text,
          md: 6,
        },
        {
          type: 'text',
          label: 'Nome Fantasia',
          name: 'social_name',
          icon: text,
          md: 6,
        },
        {
          type: 'text',
          label: 'E-mail',
          name: 'email',
          icon: text,
          md: 6,
        },
        {
          type: 'simpleMask',
          name: 'phone',
          label: 'Telefone',
          md: 6,
          icon: text,
          inputMask: '(##)####-####',
          outputMask: '(##)####-####',
          applyAfter: false,
          empty: null,
          alphanumeric: false,
          clearable: false
        },
        {
          type: 'simpleMask',
          name: 'cell_phone',
          label: 'Celular',
          md: 6,
          icon: text,
          inputMask: '(##)#####-####',
          outputMask: '(##)#####-####',
          applyAfter: false,
          empty: null,
          alphanumeric: false,
          clearable: false
        },
        {
          type: 'simpleMask',
          name: 'cnpj',
          label: 'CNPJ',
          md: 6,
          icon: text,
          inputMask: '##.###.###/####-##',
          outputMask: '##.###.###/####-##',
          applyAfter: false,
          empty: null,
          alphanumeric: false,
          clearable: false
        },
      ]
    },
    fields: [
      {
        icon: list,
        title: 'Dados do Cadastro',
        openGroup: true,
        type: 'form',
        items: [
          {
            type: 'select',
            name: 'type',
            formattedName: 'type_formatted',
            label: 'Tipo de Cadastro',
            align: '',
            md: '2',
            rules: [requiredSelectMultiple],
            list: true,
            readonly: false,
            disabled: false,
            multiple: true,
            items: typeRegister,
            itemText: 'text',
            itemValue: 'value'
          },
          {
            type: 'select',
            name: 'type_person',
            formattedName: 'type_person_formatted',
            label: 'Tipo Pessoa',
            align: '',
            md: '2',
            rules: [required],
            list: false,
            readonly: false,
            disabled: false,
            items: typePerson,
            default: typePerson[0].value,
            itemText: 'text',
            itemValue: 'value'
          },
          {
            type: 'text',
            name: 'code',
            formattedName: 'code',
            label: 'Código',
            align: '',
            md: '1',
            list: true,
            readonly: false,
            disabled: false,
          },
          {
            type: 'text',
            name: 'name',
            formattedName: 'name',
            label: 'Nome',
            align: '',
            md: '4',
            rules: [required],
            list: true,
            readonly: false,
            disabled: false,
            counter: 100,
          },
          {
            type: 'text',
            name: 'social_name',
            formattedName: 'social_name',
            label: 'Nome Fantasia',
            align: '',
            md: '3',
            list: false,
            readonly: false,
            disabled: true,
          },
          {
            type: 'text',
            name: 'email',
            formattedName: 'email',
            label: 'E-mail',
            align: '',
            md: '3',
            list: false,
            readonly: false,
            disabled: false,
          },
          {
            type: 'dataPicker',
            name: 'birth',
            formattedName: 'birth',
            label: 'Nascimento',
            align: '',
            md: '2',
            rules: [],
            list: false,
            readonly: false,
            disabled: false,
            noInitial: false,
            now: false,
          },
          {
            type: 'simpleMask',
            name: 'cpf',
            formattedName: 'cpf_formatted',
            label: 'CPF',
            align: '',
            md: '2',
            list: false,
            readonly: false,
            disabled: false,
            inputMask: '###.###.###-##',
            outputMask: '###########',
            applyAfter: false,
            empty: null,
            alphanumeric: false,
          },
          {
            type: 'simpleMask',
            name: 'cnpj',
            formattedName: 'cnpj_formatted',
            label: 'CNPJ',
            align: '',
            md: '3',
            list: true,
            readonly: false,
            disabled: true,
            clearable: true,
            inputMask: '##.###.###/####-##',
            outputMask: '##############',
            applyAfter: false,
            empty: null,
            alphanumeric: false,
          },
          {
            type: 'text',
            name: 'ie',
            formattedName: 'ie',
            label: 'Insc. Estadual',
            align: '',
            md: '2',
            list: false,
            readonly: false,
            disabled: true,
          },
          {
            type: 'simpleMask',
            name: 'phone',
            formattedName: 'phone_formatted',
            label: 'Telefone',
            align: '',
            md: '2',
            list: true,
            readonly: false,
            disabled: false,
            inputMask: '(##)####-####',
            outputMask: '###########',
            applyAfter: false,
            empty: null,
            alphanumeric: false,
          },
          {
            type: 'simpleMask',
            name: 'cell_phone',
            formattedName: 'cell_phone_formatted',
            label: 'Celular',
            align: '',
            md: '2',
            list: true,
            readonly: false,
            disabled: false,
            inputMask: '(##)#####-####',
            outputMask: '###########',
            applyAfter: false,
            empty: null,
            alphanumeric: false,
          },
          {
            type: 'text',
            name: 'site',
            formattedName: 'site',
            label: 'Site',
            align: '',
            md: '4',
            list: false,
            readonly: false,
            disabled: false,
          },
          {
            type: 'text',
            name: 'facebook',
            formattedName: 'facebook',
            label: 'Facebook',
            align: '',
            md: '2',
            list: false,
            readonly: false,
            disabled: false,
          },
          {
            type: 'text',
            name: 'instagram',
            formattedName: 'instagram',
            label: 'Instagram',
            align: '',
            md: '2',
            list: false,
            readonly: false,
            disabled: false,
          },
          {
            type: 'text',
            name: 'twitter',
            formattedName: 'twitter',
            label: 'Twitter',
            align: '',
            md: '2',
            list: false,
            readonly: false,
            disabled: false,
          },
          {
            type: 'percent',
            name: 'commission_percentage',
            formattedName: 'commission_percentage',
            label: 'Percentual Comissão',
            align: '',
            md: '2',
            rules: [],
            list: false,
            readonly: false,
            disabled: false,
            clearable: false,
            suffix: '%',
            length: 4,
            precision: 2,
            empty: null,
          },
        ],
      },
      // {
      //   icon: list,
      //   title: 'Filhos',
      //   openGroup: true,
      //   type: 'dataTable',
      //   service: {
      //     has: true,
      //     verb: 'get',
      //     endpoint: 'filters',
      //     queryParams: 'domain=all-types&filter[type]=category',
      //     items: 'all_types',
      //   },
      //   items: []
      // },
      {
        icon: address,
        title: 'Dados de Endereço',
        openGroup: true,
        type: 'address',
        required: true,
        items: []
      }
    ],
    footer: [
      {
        name: 'total_devolutions',
        label: 'Valor Devolvido',
        class: 'custom-footer-value',
        md: 2,
      },
    ]
  };