<template>
  <Card toolbar dark color="primary">
    <h3 slot="toolbar">Finalizar Venda</h3>
    <Totalizers :sale="sale" class="mb-4" />
    <!-- <AutoComplete 
                    ref="inputPaymentMethod" 
                    v-model="payment_method_id"
                    :items="selectPaymentMethods"
                    item-text="text"
                    item-value="value" 
                    cache-items
                    autofocus
                    rounded 
                    persistent-placeholder
                    label="Forma de Pagamento" />
    <v-text-field rounded label="Valor recebido"></v-text-field> -->
    <template>
      <h5>Selecione uma das opções:</h5>
    </template>
    <template slot="actions">
      <v-spacer></v-spacer>
      <Button label="(Enter) Finalizar" color="success" v-shortkey="['enter']" @shortkey="$emit('toggleModalList', 'generateSale')" @click="$emit('toggleModalList', 'generateSale')" />
      <Button label="(CTRL Enter) Finalizar e Transmitir NFCe" color="secondary" v-shortkey="['ctrl','enter']" dark @shortkey="$emit('toggleModalList', 'finishSaleInvoice')" @click="$emit('toggleModalList', 'finishSaleInvoice')" />
      <Button label="(Esc) Fechar" color="red" dark v-shortkey="['esc']" @shortkey="$emit('toggleModalList', 'close')" @click="$emit('toggleModalList', 'close')" />
      <v-spacer></v-spacer>
    </template>
  </Card>
</template>

<script>
import { formatCurrency } from '@/utils';
// import AutoComplete from '@/components/vuetify/AutoComplete';
import Card from '@/components/vuetify/Card';
import Button from '@/components/vuetify/Button';
import Totalizers from '../components/Totalizers';

export default {
  name: 'FinishOrderSale',
  components: { 
    // AutoComplete, 
    Card, 
    Button, 
    Totalizers },
  inject: ['sale'],
  data(){
    return {
      formatCurrency,
      selectPaymentMethods: [],
      payment_method_id: null,
    };
  },
  mounted(){
    this.setPaymentMethodSelect();
  },
  methods: {
    async setPaymentMethodSelect() {
      await this.$api.storeFront.paymentMethods().then((res) => {
        res.data.forEach((element) => {
          this.selectPaymentMethods.push({
            text: `${ element.to_s || element.name }`,
            value: element.id,
          });
        });
      });
    }
  }
};
</script>

<style>

</style>