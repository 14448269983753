<template>
  <div class="grid grid-template-columns-1 mt-1">
    <div class="content-product-items-table pl-1 pr-1" ref="scrollContainer">
      <table class="blueTable" width="100%">
        <tr v-for="(item, index) in sale.items" :key="index">
          <td width="10%"><p class="mb-1 ml-4 items">{{ index + 1 }}</p></td>
          <td width="20%"><p class="mb-1 items">{{ item.barcode }}</p></td>
          <td width="40%"><p class="mb-1 items">{{ item.name }}</p></td>
          <td width="10%"><p class="mb-1 items">{{ item.qtd }}</p></td>
          <td width="10%"><p class="mb-1 items">{{ localFormat(item.sale_value) }}</p></td>
          <td width="10%"><p class="mb-1 items">{{ localFormat(item.amount) }}</p></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from '@/utils';

export default {
  props: {
    sale:   {
      type: Object,
      required: true,
    }
  },
  name: 'ProductsListItems',
  methods: {
    localFormat(value){
      return formatCurrency(parseFloat(value));
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.scrollContainer.scrollTop = this.$refs.scrollContainer.scrollHeight;
      });
    },
  },
  watch: {
    sale: {
      handler() {
        this.scrollToBottom();
      },
      deep: true,
    },
  },
  mounted() {
    this.scrollToBottom();
  },
};
</script>

<style scoped>
table.blueTable {
  font-family: "Times New Roman", Times, serif;
  border: 0 solid #FFFFFF;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
table.blueTable td, table.blueTable th {
  border: 0px solid #FFFFFF;
  padding: 0;
}
table.blueTable tbody td {
  font-size: 13px;
}
table.blueTable tr:nth-child(even) {
  background: #D0E4F5;
}
table.blueTable thead {
  background: #0B6FA4;
  border-bottom: 5px solid #FFFFFF;
}
table.blueTable thead th {
  font-size: 17px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  border-left: 2px solid #FFFFFF;
}
table.blueTable thead th:first-child {
  border-left: none;
}

table.blueTable tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  background: #D0E4F5;
  border-top: 3px solid #444444;
}
table.blueTable tfoot td {
  font-size: 14px;
}
</style>