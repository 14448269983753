<template>
  <v-card dark color="primary" style="border-radius: 35px !important;">
      <v-toolbar color="primary" dark>
        <h3 v-text="'Lista de Produtos'" />
      </v-toolbar>
      <v-card-text>
        <ProductsListHeader />
        <div class="store-front--content---right">
          <div class="store-front--content---right----list-products">
            <ProductsListItems :sale="sale" />
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <slot></slot>
      </v-card-actions>
  </v-card>
</template>

<script>
import ProductsListHeader from './ProductsListHeader';
import ProductsListItems from './ProductsListItems';

export default {
  props: {
    sale:   {
      type: Object,
      required: true,
    }
  },
  name: 'ProductsList',
  components: { ProductsListHeader, ProductsListItems },
};
</script>

<style>

</style>