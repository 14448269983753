<template>
  <div class="store-front">
    <Row>
      <Col md="6">
        <GeneralData :sale="sale"/>
        <RegisterItem ref="RegisterItem" @setCurrentItem="setCurrentItem" @toggleModalList="toggleModalList" style="border-radius: 35px !important" :sale="sale"/>
        <ContentActions @toggleModalList="toggleModalList" style="border-radius: 35px !important"/>
      </Col>

      <Col md="6">
        <ProductsList :sale="sale">
          <Totalizers :sale="sale" />
        </ProductsList>
      </Col>
    </Row>

    <Dialog :dialog="dialogModalList" style="border-radius: 35px !important">
      <component :is="componentModal" v-bind="modalListProps" v-on="modalListActions" @toggleModalList="toggleModalList" />
    </Dialog>

    <DialogConfirmation v-bind="dialogConfirmationProps" v-on="dialogConfirmationActions" />

    <v-dialog v-model="dialog_interval_order_sale" maxWidth="32vw" style="border-radius: 35px !important;">
      <v-card>
        <v-card-title>
          <v-progress-circular indeterminate></v-progress-circular>&nbsp;&nbsp;
          <h4> Aguarde... Finalizando Venda</h4><br/>
        </v-card-title>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_interval_sefaz" maxWidth="32vw" style="border-radius: 35px !important;">
      <v-card>
        <v-card-title>
          <v-progress-circular indeterminate></v-progress-circular>&nbsp;&nbsp;
          <h4>  Aguarde... Transmitindo Cupom Fiscal para o Sefaz</h4><br/>
        </v-card-title>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_interval_sefaz_consult" maxWidth="32vw" style="border-radius: 35px !important;">
      <v-card>
        <v-card-title>
          <v-progress-circular indeterminate></v-progress-circular>&nbsp;&nbsp;
          <h4>  Aguarde... Consultando Cupom Fiscal no Sefaz</h4><br/>
        </v-card-title>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Row from '@/components/vuetify/Row';
import Col from '@/components/vuetify/Col';
import Dialog from '@/components/vuetify/Dialog';
import DialogConfirmation from '@/components/DialogConfirmation';
import GeneralData from '../components/GeneralData';
import RegisterItem from '../components/RegisterItem';
import ContentActions from '../components/ContentActions';
import ProductsList from '../components/ProductsList';
import Totalizers from '../components/Totalizers';
import ModalList from '../components/ModalList';
import CustomerCPF from '../components/CustomerCPF';
import FindItem from '../components/FindItem';
import RemoveItem from '../components/RemoveItem';
import Invoices from '../components/Invoices';
import OrderSales from '../components/OrderSales';
import FinishOrderSale from '../components/FinishOrderSale';
import Print from '../mixins/Print';
import { messageErrors } from '@/utils';

const COMPONENT_MODAL = Object.freeze({
  customer: ModalList,
  customerCPF: CustomerCPF,
  finish: FinishOrderSale,
  findItem: FindItem,
  invoices: Invoices,
  orderSales: OrderSales,
  removeItem: RemoveItem,
  seller: ModalList,
});

export default {
  name: 'StoreFront',
  components: {
    Row,
    Col,
    Dialog,
    DialogConfirmation,
    GeneralData,
    RegisterItem,
    ContentActions,
    ProductsList,
    Totalizers
  },
  data() {
    return {
      interval_sefaz: null,
      sale: {
        initiated: false,
        orderDate: new Date().toISOString().substr(0, 10),
        exitDate: new Date().toISOString().substr(0, 10),
        typeSale: 'retail',
        sale_order: null,
        company: {
          cnpj: '',
          name: ''
        },
        invoice_settings: {},
        nature_operation_state: {},
        buyer: {},
        customer: {
          id: null,
          name: null,
          cpf: null,
        },
        seller: {
          id: null,
          name: null,
        },
        qtd_items: 0,
        qtd_volumes: 0,
        discount: 0,
        sub_total_value: 0,
        total_value: 0,
        items: [],
        payments: [],
        totalizers: {
          qtd_items: 0,
          qtd_volumes: 0,
          total_value: 0,
          discount: 0,
          final_value: 0,
          valuePay: 0,
          lackPay: 0,
        },
      },
      invoice_settings: {},
      componentModal: null,
      dialogModalList: false,
      modalListProps: {},
      modalListActions: {},
      company: {},
      invoiceSetting: {},
      natureOperation: {},
      natureOperationState: {},
      stock: {},
      invoices: {},
      orderSales: {},
      dialogConfirmation: false,
      dialogConfirmationProps: {},
      dialogConfirmationActions: {},
      loadingFinish: false,
      dialogInterval: {},
      dialog_interval_sefaz: false,
      dialog_interval_sefaz_consult: false,
      dialog_interval_order_sale: false,
    };
  },
  provide() {
    return {
      company: this.sale.company,
      sale: this.sale,
    };
  },
  async mounted() {
    await this.me();
    await this.getBuyer();
    await this.getStocks();
    await this.getInvoiceSettings();
    await this.getPaymentMethods();
  },
  mixins: [Print],
  methods: {
    async me () {
      await this.$api.storeFront.me().then((res) =>  {
        this.getCompany(res.data.company.id);
        localStorage.setItem('company', JSON.stringify(this.sale.company));
      }).catch((err) =>  {
        console.log(err);
      });
    },
    async getCompany(id) {
      await this.$api.storeFront.company(id).then((res) =>  {
        this.company = res.data;
        this.sale.company = res.data;
        localStorage.setItem('company', JSON.stringify(this.company));
        this.getNatureOperations();
      }).catch((err) =>  {
        console.log(err);
      });
    },
    async getPaymentMethods() {
      await this.$api.storeFront.paymentMethods().then((res) =>  {
        this.payment_method = res.data.find(payment_method => payment_method.description === 'DINHEIRO');
        this.sale.payment_method_id = this.payment_method.id;
        localStorage.setItem('payment_method', JSON.stringify(this.payment_method));
      }).catch((err) =>  {
        console.log(err);
      });
    },
    async getInvoiceSettings() {
      await this.$api.storeFront.invoiceSettings().then((res) =>  {
        this.invoice_settings = res.data.find(invoice => invoice.type_invoice === 'nfce');
        this.sale.invoice_settings = this.invoice_settings;
        localStorage.setItem('invoice_settings', JSON.stringify(this.sale.invoice_settings));
        if (!this.invoice_settings) {
          this.$noty.error('Nenhuma configuração de NFCe foi encontrada.');
        }
        return this.invoice_settings;
      }).catch((err) =>  {
        console.log(err);
      });
    },
    async getNatureOperations() {
      await this.$api.storeFront.graphqlNatureOperations().then(async (res) =>  {
        this.natureOperation = res.data.data.find(nature => nature.type_nature === 'nfce');
        this.sale.nature_operation = this.natureOperation;
        localStorage.setItem('nature_operation', JSON.stringify(this.natureOperation));
        if (!this.natureOperation) this.$noty.error('Nenhuma Natureza de Operação do tipo Cupom Fiscal foi encontrada.');
        this.sale.nature_operation_id = this.natureOperation.id;
        this.natureOperationState = this.natureOperation.nature_operation_states.find(state => state.uf_state === this.company.addresses[0].uf);
        this.sale.nature_operation_state = 
        await this.$api.storeFront.natureOperationState(this.natureOperation.id, this.natureOperationState.id).then((res) =>  {
          this.sale.nature_operation_state = res.data;
          localStorage.setItem('nature_operation_state', JSON.stringify(this.sale.nature_operation_state));
        });
        
      }).catch((err) =>  {
        console.log(err);
      });
    },
    async getBuyer () {
      await this.$api.storeFront.buyer().then((res) => {
        if(res.data.length == 0) {
          this.$noty.error('Nenhum cliente cadastro corretamente.');
        } else {
          this.sale.buyer = res.data[0];
          localStorage.setItem('buyer', JSON.stringify(this.sale.buyer));
        }
      }).catch((err) =>  {
        console.log(err);
      });
    },
    async getStocks() {
      await this.$api.storeFront.graphqlStocks().then((res) =>  {
        this.stock = res.data.data.find(stock => stock.use_main === true);
        if (!this.stock) this.$noty.error('Nenhum Estoque foi encontrado.');
        this.sale.stock_id = this.stock.id;
        localStorage.setItem('stock', JSON.stringify(this.stock));
      }).catch((err) =>  {
        console.log(err);
      });
    },
    async getInvoices() {
      await this.$api.storeFront.invoices().then((res) =>  {
        this.invoices = res;
      }).catch((err) =>  {
        console.log(err);
      });
    },
    toggleModalList(action) {
      this.modalListActions = {
        ...action === 'customerCPF' && { setCPF: this.setCPF },
        ...action === 'findItem' && { setCurrentItem: this.setSelectedItemOnSale },
        ...action === 'removeItem' && { removeItem: this.removeItem },
        ...action === 'finish',
      };
      this.modalListProps = {
        ...action === 'invoices' && { width: 1500 },
        ...action === 'orderSales' && { company: this.company, sale: this.sale, width: 900 },
        ...action === 'customerCPF' && { width: 350 },
        ...action === 'removeItem' && { width: 350 },
        ...action === 'findItem' && { nature_operation: this.natureOperation, width: 900 },
        ...action === 'finishSaleInvoice' && { width: 800 },
      };
      this.componentModal = COMPONENT_MODAL[action];
      if(COMPONENT_MODAL[action] || action === 'close') this.dialogModalList = !this.dialogModalList;
      if(action === 'exit') {
        this.dialogConfirmationProps = { message: 'Deseja sair do PDV?', dialog: true, loading: false };
        this.dialogConfirmationActions = { noAction: this.closeDialogConfirmation, yesAction: this.exit };
      } else if(action === 'cancelSale') {
        this.dialogConfirmationProps = { message: 'Deseja cancelar a venda?', dialog: true, loading: false };
        this.dialogConfirmationActions = { noAction: this.closeDialogConfirmation, yesAction: this.resetSale };
      } else if(action === 'generateSale'){
        this.generateSale();
      } else if(action === 'finishSaleInvoice') {
        this.finish();
      } else if(action === 'closeModalList') {
        this.closeModalList();
      } else if(action === 'resetSale') {
        this.resetSale();
      }
    },
    closeDialogConfirmation () {
      this.dialogConfirmationProps.dialog = false;
    },
    setCPF(cpf) {
      this.sale.customer.cpf = cpf;
      this.toggleModalList('close');
    },
    exit () {
      localStorage.removeItem('token');
      localStorage.removeItem('buyer');
      localStorage.removeItem('stock');
      localStorage.removeItem('company');
      localStorage.removeItem('payment_method');
      localStorage.removeItem('invoice_settings');
      localStorage.removeItem('nature_operation_state');
      window.location=process.env.VUE_APP_ICERTUSWEB_FRONTEND;
    },
    setSelectedItemOnSale(payload) {
      const { bar_code_search, qtd, unit_value, nature_operation_id } = payload;
      this.$refs.RegisterItem.bar_code_search = bar_code_search;
      this.$refs.RegisterItem.qtd = qtd;
      this.$refs.RegisterItem.current_item.unitary_value = unit_value;
      this.$refs.RegisterItem.current_item.nature_operation_id = nature_operation_id;
      this.$refs.RegisterItem.getProductByBarCodeOrCode(unit_value, nature_operation_id);
      this.toggleModalList('close');
    },
    setCurrentItem({ item, qtd, unit_value = false, nature_operation_id }) {
      if(unit_value) item.unit_value_cost = unit_value;
      if(nature_operation_id) item.nature_operation_id = nature_operation_id; 
      this.sale.items.push({ ...item, number: this.get_item_number(), qtd });
      this.$refs.RegisterItem.qtd = 1;
      this.$refs.RegisterItem.bar_code_search = null;
      this.setTotalizers();
    },
    resetCurrentItem () {
      this.$refs.RegisterItem.current_item = {
        product: {
          id: null,
          name: '',
          barcode: '',
          price: 0
        },
        qtd: 1,
        unitary_value: 0,
        amount: 0,
      };
    },
    removeItem (item) {
      let error = true;
      let indexRemove = 0;
      this.sale.items.forEach((i, index) => {
        if(index == item-1){
          indexRemove = item;
          this.sale.items.splice(index, 1);
          error = false;
        }
      });

      this.setTotalizers();

      if(error) {
        this.$noty.error('Produto não localizado.');
      } else {
        this.$noty.success(`Item ${indexRemove} removido com sucesso.`);
      }
        
      this.toggleModalList('close');
      this.resetCurrentItem ();
    }, 
    async finish () {
      try {
        this.sale.invoice_settings = this.invoice_settings;
        this.dialog_interval_order_sale = true;
        const sale = await this.$api.storeFront.generateSale({ sale: this.sale });
        this.dialog_interval_order_sale = false;
        this.dialog_interval_sefaz = true;
        let invoice = await this.$api.storeFront.generateInvoice({ id: sale.id, sale: this.sale });
        if (this.sale.customer.cpf !== null) {
          invoice = await this.$api.storeFront.invoiceUpdate( invoice, this.sale.customer.cpf );
        }
        await this.$api.storeFront.invoiceTransmit( invoice.id ).then(() => {
          this.dialog_interval_sefaz = false;
          this.dialog_interval_sefaz_consult = true;
        });
        
        this.interval_sefaz = setInterval(async () => await this.printDANFE(invoice) , 5000);
         
      } catch (err) {
        if(err.response?.status === 422 || err.response?.status === 500) {
          this.$noty.error(messageErrors(err.response));
        } else {
          this.$noty.error('Erro desconhecido, tente novamente!');
        }
      }
    },  
    async printDANFE(invoice) {
      await this.$api.storeFront.invoiceShow( invoice.id ).then((res) => {
        const invoiceShow = res;
        if (invoiceShow.status === 'authorized') {
          const cnpjEmitter = this.company.cnpj.replaceAll('.','').replaceAll('/','').replaceAll('-','');
          const urlPrint = `${process.env.VUE_APP_BUCKET_NFE_URL}/${cnpjEmitter}/pdf/${invoiceShow.invoice_key}.pdf`;
          const fileName = `NFCe${invoiceShow.invoice_key}.pdf`;
          this.$downloadFile(urlPrint, fileName);
          clearInterval(this.interval_sefaz);
          this.dialog_interval_sefaz_consult = false;        }
        if (invoiceShow.status === 'rejected'){
          //ToDo
          clearInterval(this.interval_sefaz);
          this.dialog_interval_sefaz_consult = false;
          this.$noty.error(`Cupom fiscal Nº ${invoiceShow.invoice_number} com erro, abrir listagem de notas fiscais e verificar o erro!`);
        }  
        this.resetSale();
        this.toggleModalList('close');
      }).catch((err) => {
        console.log(err);
        this.$noty.error('');
      });
    },
    resetSale() {
      this.sale = {
        initiated: false,
        orderDate: new Date().toISOString().substr(0, 10),
        exitDate: new Date().toISOString().substr(0, 10),
        typeSale: 'retail',
        company: this.company,
        invoice_settings: JSON.parse(localStorage.getItem('invoice_settings')),
        nature_operation_id: JSON.parse(localStorage.getItem('nature_operation_state')).id,
        nature_operation_state: JSON.parse(localStorage.getItem('nature_operation_state')),
        buyer: JSON.parse(localStorage.getItem('buyer')),
        customer: {
          id: null,
          name: null,
          cpf: null,
        },
        seller: {
          id: null,
          name: null,
        },
        qtd_items: 0,
        qtd_volumes: 0,
        discount: 0,
        sub_total_value: 0,
        total_value: 0,
        items: [],
        payments: JSON.parse(localStorage.getItem('payment_method')),
        stock_id: JSON.parse(localStorage.getItem('stock')).id,
        totalizers: {
          qtd_items: 0,
          qtd_volumes: 0,
          total_value: 0,
          discount: 0,
          final_value: 0,
          valuePay: 0,
          lackPay: 0,
        },
      };
      this.resetCurrentItem();
      this.getNatureOperations();
      this.getBuyer();
      this.getInvoiceSettings();
      this.getPaymentMethods();
      this.dialogConfirmationProps.dialog = false;
    },
    setTotalizers() {
      let qtd_volumes = 0;
      let total_value = 0;

      this.sale.items.forEach((item) => {
        total_value += Number(item.amount);
        qtd_volumes += Number(item.qtd);
      });
      this.sale.totalizers.qtd_items = this.sale.items.length;
      this.sale.totalizers.qtd_volumes = qtd_volumes;
      this.sale.totalizers.total_value = total_value;
      this.sale.totalizers.discount = 0;
      this.sale.totalizers.final_value = total_value - this.sale.totalizers.discount;
    },
    get_item_number() {
      if(this.sale.items?.length == 0)
        return 1;

      return parseFloat(this.sale.items.slice(-1)[0].number) + parseFloat(1);
    },
    async generateSale(print = true) {
      this.dialog_interval_order_sale = true;
      await this.$api.storeFront.generateSale({ sale: this.sale }).then(async (res) => {
        if (print) this.printNonTax(res);
        this.dialog_interval_order_sale = false;
      }).catch((err) =>  {
        this.$noty.error(err);
      }); 
      this.resetSale();
      this.toggleModalList('close');
    },
    closeModalList() {
      this.toggleModalList('close');
    },
  }
};
</script>

<style lang="scss">
@import '../styles.scss';
</style>