import StoreFrontSchema from './schemas/StoreFrontSchema';
import StoreFront from './pages/StoreFront';

export default [
  {
    path: StoreFrontSchema.routes.pdv.path,
    name: StoreFrontSchema.routes.pdv.name,
    component: StoreFront,
    meta: StoreFrontSchema.routes.pdv.meta,
  }
];