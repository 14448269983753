import { users, note, box } from '@/constants/icons';

export default {
    domain: 'store_front',
    title: '',
    description: '',
    routes: {
      pdv: {
        name: 'pdv',
        path: '/',
        meta: { typePage: 'pdv', requiresAuth: true, }
      },
    },
    invoicesHeaders: [
      {
        text: 'Ṇ° Nota',
        value: 'invoice_number',
        align: 'start',
      },
      {
        text: 'Chave Nota',
        value: 'invoice_key',
        align: 'start',
      },
      {
        text: 'Status',
        value: 'status',
        align: 'start',
      },
      {
        text: 'Valor Total',
        value: 'totals_note',
        align: 'start',
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'start',
      }
    ],
    orderSalesHeaders: [
      {
        text: 'Ṇ° Pedido',
        value: 'sale_order',
        align: 'start',
      },
      {
        text: 'Data de Emissão',
        value: 'orderDate_formatted',
        align: 'start',
      },
      {
        text: 'NFCe',
        value: 'note',
        align: 'start',
      },
      {
        text: 'Status',
        value: 'status_formatted',
        align: 'start',
      },
      {
        text: 'Valor Total',
        value: 'totals_order_formatted',
        align: 'start',
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'start',
      }
    ],
    actions: [
      {
        icon: users,
        label: '(F2) Cliente',
        action: 'customerCPF',
        shortkey: ['f2'],
        disabled: false,
      },
      {
        icon: box,
        label: '(F3) Vendas',
        action: 'orderSales',
        shortkey: ['f3'],
        disabled: false,
      },
      {
        icon: note,
        label: '(F4) Notas Fiscais',
        action: 'invoices',
        shortkey: ['f4'],
        disabled: false,
      },
      // {
      //   icon: dataRegisters,
      //   label: 'Estoques',
      //   action: 'openStocks',
      //   disabled: false,
      // },
      // {
      //   icon: dataRegisters,
      //   label: 'Produtos',
      //   action: 'openProducts',
      //   disabled: false,
      // },
      // {
      //   icon: dataRegisters,
      //   label: 'Desconto',
      //   action: 'discount',
      //   disabled: false,
      // },
      // {
      //   icon: dataRegisters,
      //   label: 'Tipo de Venda',
      //   action: 'openDialogTypeSale',
      //   disabled: false,
      // },
      {
        icon: null,
        label: '(F10) Sair',
        action: 'exit',
        shortkey: ['f10'],
        disabled: false,
      },
      // {
      //   icon: dataRegisters,
      //   label: 'Suprimento',
      //   action: 'supply',
      //   disabled: false,
      // },
      // {
      //   icon: dataRegisters,
      //   label: 'Sangria',
      //   action: 'bleeding',
      //   disabled: false,
      // },
    ],
  };