import Vue from 'vue';

Vue.filter('formatCurrency', function (value, params = false) {
  value = parseFloat(value);

  if(!params)
    return `R$ ${value.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\\,)/g, '$1.')}`;

  if(params.type === 'percent')
    return `${value.toFixed(params.precision ? params.precision : 2).replace('.', ',').replace(/(\d)(?=(\d{3})+\\,)/g, '$1.')}%`;

  if(params.type === 'decimal')
    return `${value.toFixed(params.precision ? params.precision : 2).replace('.', ',').replace(/(\d)(?=(\d{3})+\\,)/g, '$1.')}`;

  if(params.type === 'no_decimal')
    return 'R$';
});