/* eslint-disable no-unused-vars */
import { axiosInstanceICertus } from '@/service';

const VUE_APP_REDIRECT_ID = process.env.VUE_APP_REDIRECT_ID;
const VUE_APP_ENV = process.env.VUE_APP_ENV;
const VUE_APP_API_GATEWAY = process.env.VUE_APP_API_GATEWAY;
const VUE_APP_URL = process.env.VUE_APP_URL;

const AuthICertusCommands = () => ({
  loginURL() {
    localStorage.clear();
    const response_type = 'token';
    const client_id = VUE_APP_REDIRECT_ID;
    const redirect_uri = encodeURIComponent(`${VUE_APP_URL}/auth/redirect`);
    const scope = 'core';
    const state = new Date().getTime();

    window.location = `${VUE_APP_API_GATEWAY}/oauth/auth?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&state=${state}`;
  },
  authRedirect(token) {
    localStorage.setItem('token', token);
    window.location = `${VUE_APP_URL}`;
  }
});

export { AuthICertusCommands };