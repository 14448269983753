<template>
  <div>
    <v-card style="border-radius: 35px !important">
      <v-card-title>
        <div class="img-logo">
          <img src="@/assets/images/ICertus-Logo.png" width="100" alt="">
        </div>
        <h4 class="text--h4 text-primary ml-1">PDV 1.1</h4>
        <v-spacer></v-spacer>
        <span>{{ this.date}}</span>
      </v-card-title>
      <!-- <v-card-text>
        <v-col md="12">
          <v-img
          :height="150"
          :width="150"
          inline
          :src="sale.company.logo_url || '@/assets/images/ICertus-Logo.png'"
          ></v-img>
        </v-col>
      </v-card-text> -->
      <v-card-title>
        <span>
          Empresa: <b>{{ sale.company.cnpj }} - {{ sale.company.name }}</b> <br/>
          <!-- Operador(a): <b></b> <br/> -->
          Cliente: <b>{{ sale.customer.cpf }}</b>
        </span>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'GeneralData',
  inject: ['company'],
  props: {
    sale:   {
      type: Object,
      required: true,
    }
  },
  data(){
    return {
      date: '',
    };
  },
  methods: {
    getDate(){
      var agora = new Date();
      var horas = agora.getHours();
      var minutos = agora.getMinutes();
      var segundos = agora.getSeconds();

       var dia = agora.getDate();
      var mes = agora.getMonth() + 1; 
      var ano = agora.getFullYear();

      dia = (dia < 10) ? '0' + dia : dia;
      mes = (mes < 10) ? '0' + mes : mes;

      var formatoData = dia + '/' + mes + '/' + ano;

      horas = (horas < 10) ? '0' + horas : horas;
      minutos = (minutos < 10) ? '0' + minutos : minutos;
      segundos = (segundos < 10) ? '0' + segundos : segundos;

      var formatoHora = horas + ':' + minutos + ':' + segundos;

      this.date =  formatoData + ' - '+ formatoHora;
    },
  },
  mounted(){
    setInterval(this.getDate, 1000);
  }
};
</script>

<style>

</style>