<template>
  <div>
    <v-row>
      <v-col v-if="!noSearch">
        <TextField
          v-model="search"
          label='pesquisar'
          class="content-appointments--boddy---right----customers-----search-customer" />
      </v-col>
      <v-col>
        <slot name="custom-header" />
      </v-col>
    </v-row>

    <v-data-table
      v-model="selected"
      v-if="noSync"
      class="datatable"
      dense
      :item-key="itemKey"
      :loading="loading"
      :search="search"
      :headers="headers"
      :items="localItems"
      :items-per-page="itemsPerPage"
      :single-select="singleSelect"
      :show-select="showSelect"
      :hide-default-header="hideDefaultHeader"
      :hide-default-footer="hideDefaultFooter"
      @click:row="clickRow"
      @input="handleAction">

      <template v-slot:[`item.actions`]="props" style="width: 200px">
        <slot name="actions" :props="props" />
      </template>
    </v-data-table>

    <v-data-table
      v-model="selected"
      v-else
      class="datatable"
      dense
      :item-key="itemKey"
      :loading="loading"
      :search="search"
      :headers="headers"
      :items="localItems"
      :items-per-page="itemsPerPage"
      :options.sync="options"
      :server-items-length="totalLocalItems"
      :single-select="singleSelect"
      :show-select="showSelect"
      :hide-default-header="hideDefaultHeader"
      :hide-default-footer="hideDefaultFooter"
      @click:row="clickRow"
      @input="handleAction">

      <template v-slot:[`item.actions`]="props" style="width: 200px">
        <slot name="actions" :props="props" />
      </template>
    </v-data-table>
    <v-col>
      <v-col v-if="hideDefaultFooter" cols="12" sm="12">
        <v-row>
          <v-col
            cols="12"
            sm="2"
            md="2"
            lg="2"
            xl="2">
              <v-select v-model="perPage"
                :items="perPages"
                label="Itens por página"
                @change="handlePerPageChange(perPage)" />
          </v-col>
          <v-col cols="12"
            sm="10"
            md="10"
            lg="10"
            xl="10">
              <v-pagination
                v-model="page"
                class="pagination--hide-aria-label"
                :length.sync="totalPages"
                total-visible="7"
                :next-icon="icons.arrowRight"
                :prev-icon="icons.arrowLeft"
                @input="handlePageChange(page)" />
          </v-col>
        </v-row>
      </v-col>
    </v-col>
  </div>
</template>

<script>
import _ from 'lodash';
import { arrowLeft, arrowRight } from '@/utils/icons';
import TextField from '@/components/vuetify/TextField';

export default {
  name: 'DataTable',
  components: { TextField },
  props: {
    noSearch: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    noSync: {
      type: Boolean,
      default: false,
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: [Object, Array],
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    hideDefaultHeader: {
      type: Boolean,
      default: false,
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
    totalPages: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      icons: {
        arrowLeft: arrowLeft,
        arrowRight: arrowRight,
      },
      search: '',
      selected: [],
      options: {},
      page: 1,
      perPage: 10,
      perPages: [5, 10, 25, 50, 100],
    };
  },
  watch: {
    options: {
      handler() {
        this. getItems();
      },
      deep: true,
    },
    search: _.debounce(function() {
      this.options.page = 1;
      this. getItems();
    }, 700),
  },
  computed: {
    localItems: {
      get() {
        return this.noSync ? this.items : this.items.data;
      },
      set(value) {
        this.localItems = value;
      }
    },
    totalLocalItems: {
      get() {
        return this.items.total;
      },
      set(value) {
        this.items = value;
      }
    },
    itemsPerPage() {
      return parseInt(this.perPage) || 10;
    },
  },
  methods: {
    getItems() {
      this.$emit('getItems', {
        ...this.options,
        filter: this.search,
        search_global: true
      });
    },
    handleAction() {
      this.$emit('selected', this.selected);
    },
    clickRow(data) {
      if(this.singleSelect) {
        this.selected.length && data.id === this.selected[0].id ? this.selected = [] :  this.selected = [data];
      }else{
        const filter = this.selected.find((item) => {
          return item.id === data.id;
        });

        if(!filter) {
          this.selected.push(data);
        }else{
          this.selected.forEach((item, index) => {
            if(item.id === data.id) {
              this.selected.splice(index, 1);
            }
          });
        }
      }
      this.$emit('selected', this.selected);
    },
    reloadList() {
      this.$emit('reloadListData', { page: this.page, per_page: this.perPage });
    },
    handlePageChange(value) {
      this.page = value;
      this.$emit('reloadPageInfo', { page: this.page, per_page: this.perPage });
      this.reloadList();
    },
    handlePerPageChange(size = 10) {
      this.perPage = size;
      this.page = 1;
      this.$emit('reloadPageInfo', { page: this.page, per_page: this.perPage });
      this.reloadList();
    },
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  width: 100%;
}
</style>