<template>
  <v-card class="mt-3" >
    <v-toolbar color="primary" dark>
      <h4 v-text="l.title" />
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-spacer></v-spacer>
        <v-chip class="mr-1 mb-1" v-if="existItems()" style="color: green;">Caixa Livre</v-chip>
        <v-chip class="mr-1 mb-1" v-else style="color: red;">Caixa Ocupado</v-chip>
      </v-row>
      <v-form action="">
        <Row>
          <Col md="2">
            <TextField ref="inputQtd" v-model="qtd" rounded label="Quantidade" focused v-shortkey="['alt','q']" @click="dialogQtd = true" />
          </Col>

          <Col md="10">
            <TextField 
              ref="inputBarCode" 
              v-model="bar_code_search" 
              rounded 
              autofocus
              persistent-placeholder
              label="Código de Barras/Código" 
              placeholder="Digite o código de barras ou o código do produto para adicionar a venda" 
              @change="getProductByBarCodeOrCode()" />
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <p v-text="l.infosCurrentItem.product" />
            <h1 v-text="current_item.product.name" style="color: black"/>
          </Col>

          <Col md="12" class="d-flex justify-start">
            <div class="mx-3" v-for="(item, index) in infosCurrentItem" :key="index">
              <p v-text="item.label" />
              <h1 v-text="item.value" style="color: black"/>
            </div>
          </Col>
        </Row>
      </v-form>
    </v-card-text>

    <v-card-actions class="d-flex flex-wrap justify-space-between pt-3 pb-0">
      <Button 
        :disabled="existItems()"
        color="error" 
        class="mb-3 mr-3" 
        :label="`(F6) ${l.actions.cancel}`" 
        v-shortkey="['f6']"
        @shortkey="!existItems() ? $emit('toggleModalList', 'cancelSale'): ''" 
        @click="$emit('toggleModalList', 'cancelSale')" />
      <Button 
        :disabled="existItems()"
        color="warning" 
        class="mb-3 mr-3" 
        :label="`(F7) ${l.actions.remove}`" 
        v-shortkey="['f7']"
        @shortkey="!existItems() ? $emit('toggleModalList', 'removeItem') : ''" 
        @click="$emit('toggleModalList', 'removeItem')" />
      <Button 
        color="primary" 
        class="mb-3 mr-3" 
        :label="`(F8) ${l.actions.findItem}`" 
        v-shortkey="['f8']"
        @shortkey="$emit('toggleModalList', 'findItem')" 
        @click="$emit('toggleModalList', 'findItem')" />
      <Button 
        :disabled="existItems()"
        color="success" 
        class="mb-3 mr-3" 
        :label="`(F9) ${l.actions.finish}`" 
        v-shortkey="['f9']" 
        @shortkey="!existItems() ? $emit('toggleModalList', 'finish') : ''" 
        @click="$emit('toggleModalList', 'finish')" />
    </v-card-actions>
  </v-card>
</template>

<script>
import Row from '@/components/vuetify/Row';
import Col from '@/components/vuetify/Col';
import TextField from '@/components/vuetify/TextField';
import Button from '@/components/vuetify/Button';
import { formatCurrency } from '@/utils';

export default {
  name: 'RegisterItem',
  components: { Row, Col, TextField, Button },
  props: {
    sale:   {
      type: Object,
      required: true,
    }
  },
  // inject: ['sale'],
  data() {
    return {
      formatCurrency: formatCurrency,
      qtd: 1,
      bar_code_search: null,
      current_item: {
        product: {
          id: null,
          name: '',
          barcode: '',
          price: 0
        },
        qtd: 1,
        unitary_value: 0,
        amount: 0,
      },
      loadingBarCode: false,
      selectProducts: [],
    };
  },
  computed: {
    l () {
      return this.$locales.pt.PDV.registerItem;
    },
    infosCurrentItem () {
      return [
        { label: this.l.infosCurrentItem.qtd, value: formatCurrency(this.current_item.qtd, { type: 'decimal' }) },
        { label: this.l.infosCurrentItem.unitary_value, value: formatCurrency(this.current_item.unitary_value) },
        { label: this.l.infosCurrentItem.amount, value: formatCurrency(parseFloat(this.current_item.qtd) * parseFloat(this.current_item.unitary_value)) },
      ];
    }
  },
  methods: {
    existItems(){
      return this.sale.totalizers.qtd_items === 0;
    },
    async getProductByBarCodeOrCode(unit_value = false, nature_operation_id = false) {
      if (this.bar_code_search === null) return;
      if (this.bar_code_search === '') {
        this.$noty.error('Digite o código ou código de barra do produto.');
        return false;
      } 
      await this.$api.storeFront.getProductByBarCodeOrCode(this.bar_code_search).then((res) => {
        if(res.data.length === 0) return this.$noty.error('Produto não encontrado.');
        if( unit_value !== false){
          res.data[0].sale_value = unit_value; 
        }
        if (nature_operation_id !== false){
          res.data[0].nature_operation_id = nature_operation_id; 
        }

        const item = { ...res.data[0], amount: res.data[0].sale_value * this.qtd };

        this.current_item = {
          product: {
            id: item.id,
            barcode: item.barcode,
            name: item.name,
            price: item.sale_value,
          },
          qtd: this.qtd,
          unitary_value: item.sale_value,
          unit_cost_value: item.purchase_cost,
          amount: item.sale_value * this.qtd,
        };

        this.$emit('setCurrentItem', { item, qtd: this.qtd });
        this.bar_code_search = null;
      });
    },
    async setProductsBarcodeSelect() {
      await this.$api.storeFront.graphqlProducts().then((res) => {
        res.data.data.forEach((element) => {
          this.selectProducts.push({
            text: `${ element.code || element.barcode } - ${ element.to_s || element.name }`,
            value: `${ element.code || element.barcode }`,
          });
        });
      });
    }
  }
};
</script>

<style>

</style>