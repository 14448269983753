import { check } from '@/utils/icons';
import PDV from './PDV';

export default {
  index: {
    alerts: {
      createdRegister: 'Registro criado com sucesso',
      updatedRegister: 'Registro atualizado com sucesso',
      deletedRegister: 'Registro excluído com sucesso',
      errorCreatingRegister: 'Erro ao criar o registro',
    },
    dynamicListPage: {
      listActions: {
        icon: check,
        label: 'Ativar/Desativar',
        action: 'situation',
      }
    },
    dynamicFormPage: {},
  },
  PDV
};