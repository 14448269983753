<template>
  <v-dialog v-model="dialog" :width="maxWidth">
    <v-card style="border-radius: 35px !important">
      <v-card-text class="px-0 py-0">
        <slot />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    maxWidth: {
      type: Number,
      default: 800
    },
    noTitle: {
      type: Boolean,
      default: false
    },
    noActions: {
      type: Boolean,
      default: false
    },
  }
};
</script>

<style>

</style>