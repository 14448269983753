<template>
  <Card toolbar dark color="primary">
    <h3 slot="toolbar">Vendas</h3>

    <template>
      <DataTable
        no-sync
        no-search
        :headers="headers"
        :loading="loading"
        :items="orderSales"
        :hide-default-footer="true"
        :page="page"
        :per-page="per_page"
        :total-pages.sync="totalPages"
        @reloadPageInfo="setPageInfo"
        @reloadListData="getOrderSales" >

        <template v-slot:actions="props">
          <Button type-icon :icon="$icons.printerPos" color="primary" @click="printOrderSale(props.props.item)" />
          <Button type-icon :icon="$icons.note" color="green" @click="transmitOrderSale(props.props.item)" />
          <Button type-icon :icon="$icons.destroy" color="red" @click="cancelOrderSale(props.props.item)" />
        </template>
      </DataTable>
    </template>

    <template slot="actions">
      <Button label="(Esc) Fechar" color="error" v-shortkey="['esc']" @shortkey="$emit('toggleModalList', 'close')" @click="$emit('toggleModalList', 'close')" />
    </template>
  </Card>
</template>

<script>
import Card from '@/components/vuetify/Card';
import Button from '@/components/vuetify/Button';
import DataTable from '@/components/vuetify/DataTable';
import Print from '../mixins/Print';
import { messageErrors } from '@/utils';

export default {
  name: 'OrderSales',
  components: { Card, Button, DataTable },
  props: {
    sale: {
      type: Object,
      required: true
    },
    company: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      interval_sefaz: null,
      orderSales: [],
      loading: false,
      page: 1,
      per_page: 10,
      totalPages: 0,
    };
  },
  async mounted() {
    this.getOrderSales();
  },
  computed: {
    l () {
      return this.$locales.pt.PDV.orderSales;
    },
    headers () {
      return this.$schemas.storeFront.orderSalesHeaders;
    }
  },
  mixins: [Print],
  methods: {
    setPageInfo(payload){
      this.page = payload.page;
      this.per_page = payload.per_page;
    },
    async getOrderSales() {
      this.loading = true;
      this.$api.storeFront.orderSales(this.page, this.per_page).then((res) =>  {
        this.orderSales = res.data;
        this.totalPages = parseInt(res.paginator.total_pages);
        this.loading = false;
      }).catch((err) =>  {
        console.log(err);
        this.loading = false;
      });
    },
    printOrderSale(sale) {      
      this.printNonTax(sale);
      this.$emit('resetSale',  { action: 'resetSale' });
      this.$emit('closeModalList',  { action: 'closeModalList' });
    },
    async transmitOrderSale(sale) {
      if (sale.note !== ''){
        try {
          this.$emit('getInvoiceSettings',  { action: 'getInvoiceSettings' });
          sale = {
            ...sale,
            invoice_settings: JSON.parse(localStorage.getItem('invoice_settings')),
          };
          let invoice = await this.$api.storeFront.generateInvoice({ id: sale.id, sale: sale });
          await this.$api.storeFront.invoiceTransmit( invoice.id );
          this.interval_sefaz = setInterval(async () => await this.printDANFE(invoice) , 5000);
        } catch (err) {
          console.log(err);
          if(err.response?.status === 422) {
            this.$noty.error(messageErrors(err.response));
          } else {
            this.$noty.error('Erro desconhecido, tente novamente!');
          }
        }
      } else {
        this.$noty.error('Venda já com Cupom fiscal emitido, verificar status na listagem de Cupom Fiscal.');
      }
    },
    async printDANFE(invoice) {
      await this.$api.storeFront.invoiceShow( invoice.id ).then((res) => {
        const invoiceShow = res;
        if (invoiceShow.status === 'authorized') {
          const cnpjEmitter = this.company.cnpj.replaceAll('.','').replaceAll('/','').replaceAll('-','');
          const urlPrint = `${process.env.VUE_APP_BUCKET_NFE_URL}/${cnpjEmitter}/pdf/${invoiceShow.invoice_key}.pdf`;
          const fileName = `NFCe${invoiceShow.invoice_key}.pdf`;
          this.$downloadFile(urlPrint, fileName);
          clearInterval(this.interval_sefaz);
        }
        if (invoiceShow.status === 'rejected'){
          clearInterval(this.interval_sefaz);
          this.$noty.error(`Cupom fiscal Nº ${invoiceShow.invoice_number} com erro, abrir listagem de notas fiscais e verificar o erro!`);
        }
        this.getOrderSales();
      }).catch((err) => {
        console.log(err);
        this.$noty.error('');
      });
    },
    async cancelOrderSale(sale) {
      this.loading = true;
      await this.$api.storeFront.cancelSale(sale.id).then(() => {
        this.$noty.success(this.l.Cancel);
        this.loading = false;
        this.getOrderSales();
      }).catch((err) =>  {
        console.log(err);
        this.$noty.error(err);
      });
    },
  }
};
</script>

<style>

</style>