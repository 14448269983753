import AuthSchema from '@/views/Auth/schemas/AuthSchema';
import StoreFrontSchema from '@/views/StoreFront/schemas/StoreFrontSchema';


const schemas = Object.freeze({
  auth: AuthSchema,
  storeFront: StoreFrontSchema,
});

export default {
  install: function (Vue) {
    Vue.prototype.$schemas = schemas;
    Vue.schemas = schemas;
  }
};