<template>
  <Card toolbar dark color="primary">
    <h3 slot="toolbar">Digite o código / código de barra:</h3>

    <template>
      <TextField class="item-number" id="item-number" v-model="item" autofocus label="N° Item" v-on:keyup.enter="$emit('removeItem', item)" rounded />
    </template>

    <template slot="actions">
      <Button label="Remover" color="primary" @click="$emit('removeItem', item)" />
      <Button label="Cancelar" color="red" dark @click="$emit('toggleModalList', 'close')" />
    </template>
  </Card>
</template>

<script>
import Card from '@/components/vuetify/Card';
import TextField from '@/components/vuetify/TextField';
import Button from '@/components/vuetify/Button';

export default {
  name: 'RemoveItem',
  components: { Card, TextField, Button },
  data() {
    return {
      item: null,
    };
  },
  mounted() {
    document.getElementById('item-number').focus();
  }
};
</script>

<style>

</style>