const zipCode = '##.###-###';
const cell_phone = '(##)#####-####';
const cpf = '###.###.###-##';
const cnpj = '##.###.###/####-##';
const cpf_cnpj = ['###.###.###-##', '##.###.###/####-##'];

export {
  zipCode,
  cell_phone,
  cpf,
  cnpj,
  cpf_cnpj
};