<template>
  <v-row class="pa-2">
    <v-col md="4">
      <h4 class="resume">Quantidade: <span class="font-weight-light">{{ sale.totalizers.qtd_items }} itens</span></h4>
      <h4>Volumes: <span class="font-weight-light">{{ qtd_volumes }} itens</span></h4>
      <h4>Subtotal: <span class="font-weight-light">{{ total_value }}</span></h4>
      <h4>Desconto: <span class="font-weight-light">{{ discount }}</span></h4>
    </v-col>
    <v-col md="4">
    </v-col>
    <v-col md="4">
      <p class="my-0">Total</p>
      <h1>{{ final_value }}</h1>
    </v-col>
  </v-row>
</template>

<script>
import { formatCurrency } from '@/utils';

export default {
  props: {
    sale:   {
      type: Object,
      required: true,
    }
  },
  name: 'Totalizers',
  computed: {
    qtd_volumes () {
      return this.sale.items.reduce((i, item) => parseInt(i) + parseInt(item.qtd), 0);
    },
    total_value() {
      return formatCurrency(parseFloat(this.sale.totalizers.total_value));
    },
    discount() {
      return formatCurrency(parseFloat(this.sale.totalizers.discount));
    },
    final_value() {
      return formatCurrency(parseFloat(this.sale.totalizers.final_value));
    },
  }
};
</script>

<style>

</style>