import AuthService from '@/views/Auth/services/AuthService';
import { RegistersService, RegisterCommands } from '@/views/Registers/Registers/services/RegistersService';
import { StoreFrontCommands } from '@/views/StoreFront/services/StoreFrontService';
import { NFCommands } from '@/views/StoreFront/services/NfService';
import { AuthICertusCommands } from '@/views/StoreFront/services/AuthICertusService';

const api = Object.freeze({
  auth: AuthService,
  registers: { ...RegistersService, ...RegisterCommands() },
  storeFront: { ...StoreFrontCommands() },
  nf: { ...NFCommands() },
  authICertus: { ...AuthICertusCommands() },
});

export default {
  install: function (Vue) {
    Vue.prototype.$api = api;
    Vue.api = api;
  }
};