import axios from 'axios';

const axiosInstanceICertus = axios.create({
  baseURL: process.env.VUE_APP_URL_API_ICERTUS,
});

const axiosInstanceNF = axios.create({
  baseURL: process.env.VUE_APP_URL_API_NF,
});

const interceptorsResponse = (instance)=>  {
  instance.interceptors.response.use(response => {
    return response;
  },
  (error) => {
    if(error.response?.status == 401){
      localStorage.removeItem('token');
      window.location = `${process.env.VUE_APP_URL}/login`;
      return Promise.reject(error);
    }
    console.warn('Timeout error');
    return Promise.reject(error);
  });
};

const removeMask = (str)  => {
    return str.match(/\d/g).join('');
};

axiosInstanceICertus.interceptors.request.use(
  async config => {
    let token = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  error => {
    Promise.reject(error);
});

axiosInstanceNF.interceptors.request.use(
  async config => {
    let { cnpj } = JSON.parse(localStorage.getItem('company'));

    if (!cnpj) {
      localStorage.removeItem('token');
      window.location = `${process.env.VUE_APP_URL}`;
    }

    config.headers.ApiKey = process.env.VUE_APP_API_KEY_NF;
    config.headers.cnpj = removeMask(cnpj);

    return config;
  },
  error => {
    Promise.reject(error);
});

interceptorsResponse(axiosInstanceICertus);
interceptorsResponse(axiosInstanceNF);

export { axios, axiosInstanceICertus, axiosInstanceNF };