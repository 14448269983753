import AuthSchema from './schemas/AuthSchema';
import Login from '../Auth/pages/Login';
import AuthRedirect from '../Auth/pages/AuthRedirect';

export default [
  {
    path: AuthSchema.routes.login.path,
    name: AuthSchema.routes.login.name,
    component: Login,
    meta:{}
  },
  {
    path: AuthSchema.routes.authRedirect.path,
    name: AuthSchema.routes.authRedirect.name,
    component: AuthRedirect,
    meta:{}
  },
];