/* eslint-disable */
import { formatCurrency, formatDatePT } from '@/utils';

const Print = {
  methods: {
    getCompany() {
      return JSON.parse(localStorage.getItem('company'));
    },
    getLogo(logo) {
      if(!logo)
        return require(`@/assets/images/ICertus-Logo.png`);

      return logo;
    },
    printDanfeNFCe(transmit, sale, barCode) {
      const classes = this.defineClass();

      const screen_print = window.open('about:blank');
      screen_print.document.write('<html>');
        screen_print.document.write('<head>');
        screen_print.document.write('<title>DANFE</title>');
          screen_print.document.write('<style>');
          screen_print.document.write(classes);
          screen_print.document.write('</style>');
        screen_print.document.write('</head>');
        screen_print.document.write('<div id="print" class="conteudo tax-coupon">');
          screen_print.document.write(this.makeHeader(sale));
          screen_print.document.write(this.makeEmission(transmit));
          screen_print.document.write(this.makeTitleDANFE(sale));
          screen_print.document.write(this.makeItemsHeader(sale));
          screen_print.document.write(this.makeItems(sale));
          screen_print.document.write(this.makeTotals(sale));
          screen_print.document.write(this.makeCustomerSeller(sale));
          screen_print.document.write(this.makeDataTax(transmit));
          screen_print.document.write(this.makeQRCode(transmit, barCode));
        screen_print.document.write('</div>');
      screen_print.document.write('</html>');
      screen_print.window.print();
      screen_print.window.close();
    },

    printNonTax(sale) {
      const salePrint = {
        sale_order: sale,
        initiated: false,
        orderDate: sale.orderDate,
        exitDate: sale.exitDate,
        typeSale: 'retail',
        company: this.company,
        buyer: sale.buyer,
        customer: {
          id: sale.buyer.id,
          name: sale.buyer.name,
          cpf: sale.buyer.cnpj_cpf,
        },
        seller: {
          id: null,
          name: null,
        },
        items: sale.items.map((item, index) => {
          return {
            number: index + 1,
            code: item.product.code,
            to_s: item.product.to_s,
            qtd: item.quantity,
            sale_value: item.unit_value
          };
        }),
        payments: [],
        totalizers: {
          qtd_items: sale.items.length,
          qtd_volumes: sale.items.length,
          total_value: sale.totalizers.total_value,
          discount: sale.totalizers.discount,
          final_value: sale.totalizers.final_value,
          valuePay: 0,
          lackPay: 0,
        }
      };
      sale = salePrint;
      const classes = this.defineClass();

      const screen_print = window.open('iCertus PDV');
      screen_print.document.write('<html>');
      screen_print.document.write('<head>');
      screen_print.document.write('<title>Recibo de Venda</title>');
      screen_print.document.write('<style>');
      screen_print.document.write(classes);
      screen_print.document.write('</style>');
      screen_print.document.write('</head>');
      screen_print.document.write('<div id="print" class="conteudo tax-coupon">');
      screen_print.document.write(this.makeHeader(sale));
      screen_print.document.write(this.makeEmissionNonTax(sale));
      screen_print.document.write(this.makeTitleNonTax(sale));
      screen_print.document.write(this.makeItemsHeader(sale));
      screen_print.document.write(this.makeItems(sale));
      screen_print.document.write(this.makeTotals(sale));
      screen_print.document.write(this.makeCustomerSeller(sale));
      screen_print.document.write('</div>');
      screen_print.document.write('</html>');
      screen_print.window.print();
      screen_print.window.close();
    },
    defineClass() {
      let classes = '* { margin: 0px !important; padding: 0px !important } ';
      classes += '.tax-coupon { width: 100%; max-width: 450px; font-size: 13px; } ';
      classes += '.tax-coupon-header { text-align: center; font-weight: bold; border-bottom: 1px dashed #000; padding: 5px !important } ';
      classes += '.tax-coupon-emission { text-align: center; border-bottom: 1px dashed #000; padding: 5px !important } ';
      classes += '.tax-coupon-title-danfe { text-align: center; border-bottom: 1px dashed #000; padding: 5px 15px !important } ';
      classes += '.tax-coupon-items { border-bottom: 1px dashed #000; padding: 5px !important } ';
      classes += '.item-table { font-size: 12px; } ';
      classes += '.tax-coupon-totals { border-bottom: 1px dashed #000; padding: 5px !important } ';
      classes += '.tax-coupon-customer-seller { border-bottom: 1px dashed #000; padding: 5px !important } ';
      classes += '.tax-coupon-data-tax { text-align: center; border-bottom: 1px dashed #000; padding: 5px !important } ';
      classes += '.tax-coupon-qrcode { text-align: center; border-bottom: 1px dashed #000; padding: 5px !important } ';
      classes += '.content-qrcode { margin-top: 20px !important; display: flex; justify-content: center } ';
      
      return classes;
    },
    makeHeader(sale) {
      let html = `
        <div class="tax-coupon-header">
          <p>${sale.company?.name}</p>
          <p>CNPJ: ${sale.company?.cnpj || ''} - IE: ${sale.company?.ie || ''}</p>
          <p>${sale.company?.addresses[0]?.street || ''}, ${sale.company?.addresses[0]?.number || ''}, ${sale.company?.addresses[0]?.neighborhood || ''}</p>
          <p>${sale.company?.addresses[0]?.zip_code || ''} - ${sale.company?.addresses[0]?.city || ''}/${sale.company?.addresses[0]?.uf || ''}</p>
          <p>Fone: ${sale.company?.contacts[0]?.telephone || ''}</p>
        </div>
      `;
      return html;
    },
    makeEmission(transmit) {
      const emission = new Date(transmit.dt_receipt);
      let html = `
        <div class="tax-coupon-emission">
          <p>Emissão: ${emission.getDate()}/${emission.getMonth() + 1}/${emission.getFullYear()} - ${emission.getHours()}:${emission.getMinutes()}:${emission.getSeconds()}</p>
        </div>
      `;
      return html;
    },
    makeEmissionNonTax(sale) {
      const emission = new Date(sale.sale_order.created_at);
      let html = `
        <div class="tax-coupon-emission">
          <p>Emissão: ${emission.getDate()}/${emission.getMonth() + 1}/${emission.getFullYear()} - ${emission.getHours()}:${emission.getMinutes()}:${emission.getSeconds()}</p>
        </div>
      `;
      return html;
    },
    makeTitleDANFE(item) {
      let html = `
        <div class="tax-coupon-title-danfe">
          <p>DANFE - Documento Auxiliar de Nota Fiscal Eletrônica para Consulmidor Final</p>
          <p>Não permite aproveitamento de crédito de ICMS</p>
        </div>
      `;
      return html;
    },
    makeTitleNonTax(sale) {
      let html = `
        <div class="tax-coupon-title-danfe">
          <p>Recibo de Venda</p>
          <p>PEDIDO: ${sale.sale_order.sale_order} - Não tem valor fiscal</p>
        </div>
      `;
      return html;
    },
    makeItemsHeader(item) {
      let html = `
        <div class="tax-coupon-items">
          <table width="100%">
            <tr>
              <td><p class="item-table" class="item-table">Item</p><td>
              <td><p class="item-table">Cod</p><td>
              <td colspan="5"><p class="item-table">Descr</p><td>
            </tr>
            <tr>
              <td width="6%"><p class="item-table"></p><td>
              <td width="14%"><p class="item-table">Qtde</p><td>
              <td width="20%"><p class="item-table">Un</p><td>
              <td width="30%"><p class="item-table">Vlr Uni</p><td>
              <td width="30%"><p class="item-table">Vlr Total</p><td>
            </tr>
          </table>
        </div>
      `;
      return html;
    },
    makeItems(sale) {
      let items = '';
      const params = { type: 'decimal'};
      sale.items.forEach((item) => {
        items += `
          <tr>
            <td><p class="item-table" class="item-table">${item.number}</p><td>
            <td><p class="item-table">${item.code}</p><td>
            <td colspan="5"><p class="item-table">${item.to_s}</p><td>
          </tr>
          <tr>
            <td width="6%"> <p class="item-table"></p><td>
            <td width="14%"><p class="item-table">${item.qtd}</p><td>
            <td width="20%"><p class="item-table">UN</p><td>
            <td width="30%"><p class="item-table">${formatCurrency(item.sale_value, params)}</p><td>
            <td width="30%"><p class="item-table">${formatCurrency(item.sale_value * item.qtd, params)}</p><td>
          </tr>
        `
      });

      let html = `
        <div class="tax-coupon-items">
          <table width="100%">
            ${items}
          </table>
        </div>
      `;
      return html;
    },
    makeTotals(sale) {
      const { qtd_items, qtd_volumes, total_value, discount, final_value } =  sale.totalizers;
      let html = `
        <div class="tax-coupon-totals">
          <table width="100%">
            <tr>
              <td width="50%" align="left"><p class="item-table" class="item-table">Qtd Items</p><td>
              <td width="50%" align="right"><p class="item-table">${(qtd_items)}</p><td>
            </tr>
            <tr>
              <td width="50%" align="left"><p class="item-table" class="item-table">Qtd Volumes</p><td>
              <td width="50%" align="right"><p class="item-table">${(qtd_volumes)}</p><td>
            </tr>
            <tr>
              <td width="50%" align="left"><p class="item-table" class="item-table">Valor Total</p><td>
              <td width="50%" align="right"><p class="item-table">${formatCurrency(total_value)}</p><td>
            </tr>
            <tr>
              <td width="50%" align="left"><p class="item-table" class="item-table">Valor Desconto</p><td>
              <td width="50%" align="right"><p class="item-table">${formatCurrency(discount)}</p><td>
            </tr>
            <tr>
              <td width="50%" align="left"><p class="item-table" class="item-table">Valor Final</p><td>
              <td width="50%" align="right"><p class="item-table">${formatCurrency(final_value)}</p><td>
            </tr>
          </table>
        </div>
      `;
      return html;
    },
    makeCustomerSeller(sale) {
      let html = `
        <div class="tax-coupon-customer-seller">
          <p><strong>Vendedor: </strong>Vendedor Padrão</p>
          <p><strong>Consumidor CPF: </strong>${sale.customer?.cpf || ''}</p>
        </div>
      `;
      return html;
    },
    makeDataTax(transmit) {
      let html = `
        <div class="tax-coupon-data-tax">
          <p>Número: ${transmit.number} - Série: ${transmit.series} - Emissão: ${formatDatePT(transmit.dt_receipt)} 16:20:28</p>
          <p>Consulte pela chave de acesso em</p>
          <p>${transmit.consult_url}</p>
          <p>Chave de Acesso</p>
          <p>${transmit.key}</p>
        </div>
      `;
      return html;
    },
    makeQRCode(transmit, barCode) {
      let html = `
        <div class="tax-coupon-qrcode">
          <p>Consulta via leitor de QR Code</p>
          <div class="content-qrcode"><div><img src="${barCode}" /></div></div>
          <p style="margin-top: 20px !important">Protocolo de Autorização</p>
          <p>${transmit.protocol || ''} ${formatDatePT(transmit.dt_receipt)} 16:00:55</p>
        </div>
      `;
      return html;
    },
  },
};

export default Print;
