import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import Icons from './plugins/icons';
import Enums from './plugins/enums';
import Services from './plugins/services';
import Locales from './plugins/locales';
import Schemas from './plugins/schemas';
import './filters/FormatCurrency';
import './plugins/vuetify-mask.js';
import './plugins/toasts';
import router from './router';
import DownloadFile from './plugins/download-file';
import VueTheMask from 'vue-the-mask';
import money from 'v-money';

Vue.config.productionTip = false;
Vue.use(Icons);
Vue.use(Services);
Vue.use(Enums);
Vue.use(Locales);
Vue.use(Schemas);
Vue.use(require('vue-shortkey'));
Vue.use(DownloadFile);
Vue.use(VueTheMask);
Vue.use(money, {precision: 2});

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app');
