<template>
  <div>
    <v-card class="mt-3" style="border-radius: 35px !important">
      <v-toolbar color="primary" dark>
        <h4 v-text="l.title" />
      </v-toolbar>
      <v-card-text>
        <div class="d-flex flex-wrap">
          <Button
            color="primary"
            class="mb-3 mr-3"
            v-for="(btn, index) in actions"
            :key="index"
            :label="btn.label"
            :icon="btn.icon"
            v-shortkey="btn.shortkey"
            @shortkey="$emit('toggleModalList', btn.action)"
            @click="$emit('toggleModalList', btn.action)" />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Button from '@/components/vuetify/Button';

export default {
  name: 'ContentItem',
  components: { Button },
  inject: ['sale'],
  computed: {
    l () {
      return this.$locales.pt.PDV.contentActions;
    },
    actions () {
      return this.$schemas.storeFront.actions;
    }
  },
};
</script>

<style>

</style>