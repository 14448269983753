<template>
  <v-card style="width: 90vw; border-radius: 35px !important;">
    <v-toolbar color="primary" dark>
      <h3 v-text="'Pesquisar item'" />
    </v-toolbar>
    <v-card-text>
      <h4 class="mb-3">Escolha a quantidade e um item manualmente para adicionar a venda.</h4>
      <Row>
          <Col md="2">
            <TextField ref="inputQtd" v-model="qtd" rounded label="(Q)uantidade" focused v-shortkey.focus="['ctrl','q']" @click="dialogQtd = true" />
          </Col>

          <Col md="7">
            <AutoComplete
                    ref="inputBarCode"
                    v-model="bar_code_search"
                    :items="selectProducts"
                    item-text="text"
                    item-value="value"
                    cache-items
                    autofocus
                    rounded
                    persistent-placeholder
                    label="Código de Barras/Código/Nome do Produto"
                    placeholder="Digite o código de barras, código ou nome do produto para adicionar a venda"
                    @change="setUnitValue(bar_code_search)"
                    @click:clear="(unit_value = 0)" />
          </Col>
          <Col md="3">
            <!-- @TODO - Abstract to custom component -->
            <div class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--rounded" rounded="" focused="" value="1">
              <div class="v-input__control">
                <div class="v-input__slot">
                  <fieldset aria-hidden="true">
                    <legend style="width: 81px;"></legend>
                  </fieldset>
                  <div class="v-text-field__slot">
                    <label for="input-128" class="v-label v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Valor Unitário</label>
                    <money v-model.number.lazy="unit_value" v-bind="money" rounded label="Valor Unitário"></money>
                  </div>
                </div>
              </div>
            </div>
          </Col>
      </Row>
      <Row>
        <Col md="12">
          <AutoComplete
                    v-model="nature_operation_search"
                    :items="selectNatureOperations"
                    item-text="text"
                    item-value="value"
                    cache-items
                    rounded
                    persistent-placeholder
                    label="CFOP"
                    placeholder="Digite o CFOP ou nome da natureza de operação"
                    @change="setNatureOperation(nature_operation_search)"
                    @click:clear="(unit_value = 0)" />
          </Col>
      </Row>
    </v-card-text>

    <v-card-actions>
      <Button label="(Enter) Confirmar" color="green" dark v-shortkey="['enter']" @shortkey="$emit('setCurrentItem', { bar_code_search, qtd, unit_value, nature_operation_id })" @click="$emit('setCurrentItem', { bar_code_search, qtd, unit_value, nature_operation_id })" />
      <Button label="(Esc) Cancelar" color="red" dark v-shortkey="['esc']" @shortkey="$emit('toggleModalList', 'close')" @click="$emit('toggleModalList', 'close')" />
    </v-card-actions>
  </v-card>
</template>

<script>
import Row from '@/components/vuetify/Row';
import Col from '@/components/vuetify/Col';
import AutoComplete from '@/components/vuetify/AutoComplete';
import TextField from '@/components/vuetify/TextField';
import Button from '@/components/vuetify/Button';

import {Money} from 'v-money';

export default {
  name: 'FindItem',
  components: { AutoComplete, Button, Row, Col, TextField,
    // eslint-disable-next-line vue/no-unused-components
    Money
   },
   props: {
    nature_operation: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      bar_code_search: null,
      nature_operation_search: null,
      qtd: 1,
      selectProducts: [],
      selectNatureOperations: [],
      unit_value: '',
      nature_operation_id: '',
      dialogQtd: false,
      money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false
        }
    };
  },
  async mounted(){
    await this.setProductsBarcodeSelect();
    await this.setNatureOperationSelect();
    
    this.nature_operation_search = `${this.nature_operation.id}`;
  },
  methods: {
    async setProductsBarcodeSelect() {
      await this.$api.storeFront.graphqlProducts().then((res) => {
        res.data.data.forEach((element) => {
          this.selectProducts.push({
            text: `${ element.code } - ${ element.to_s || element.name } - ${element.barcode}`,
            value: `${ element.code || element.barcode }`,
            unit_value: element.sale_value,
          });
        });
      });
    },
    async setNatureOperationSelect() {
      await this.$api.storeFront.graphqlNatureOperations().then((res) => {
        res.data.data.forEach((element) => {
          this.selectNatureOperations.push({
            text: `${ element.to_s || element.name }`,
            value: `${ element.id }`,
            nature_operation_id: element.id,
          });
        });
      });
    },
    setUnitValue(value){
      if(value){
        const [selected] = this.selectProducts.filter((item) => item.value ===  value);
        this.unit_value = selected.unit_value;
      }
    },
    setNatureOperation(value){
      if(value){
        const [selected] = this.selectNatureOperations.filter((item) => item.value ===  value);
        this.nature_operation_id = selected.nature_operation_id;
      }
    }
  }
};
</script>
