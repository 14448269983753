<template>
  <v-dialog
    v-model="dialog"
    :max-width="maxWidth"
  >
    <v-card style="border-radius: 35px !important">
      <v-card-title class="headline">
        {{ message }}
      </v-card-title>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn rounded :color="colorButtons.noBtn" :dark="colorButtons.noBtn === 'red'" v-shortkey="['esc']" @shortkey="$emit('noAction')" @click="$emit('noAction')">(Esc) Não</v-btn>
        <v-btn rounded dark :color="colorButtons.yesBtn" :loading="loading" v-shortkey="['enter']" @shortkey="$emit('yesAction')" @click="$emit('yesAction')">(Enter) Sim</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogConfirmation',
  props: {
    maxWidth: {
      type: Number,
      default: 480
    },
    dialog: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: 'Deseja realmente excluir este registro?'
    },
    colorButtons: {
      type: Object,
      default: () => {
        return {
          noBtn: 'red',
          yesBtn: 'green'
        };
      }
    }
  },
  methods: {
  }
};
</script>