const statusAppointment = [
  { id: 1, text: 'Pendente', value: 'pending' },
  { id: 2, text: 'Concluido', value: 'done' },
  { id: 3, text: 'Cancelado', value: 'canceled' },
];

const statusOrderService = [
  { id: 1, text: 'Pendente', value: 'pending' },
  { id: 2, text: 'Finalizado', value: 'finished' },
  { id: 3, text: 'Cancelado', value: 'canceled' },
];

const typeUser = [
  {id: 1, text: 'Administrador', value: 'admin'},
  {id: 2, text: 'Gerente', value: 'manager'},
  {id: 3, text: 'Padrão', value: 'default'},
  {id: 4, text: 'Caixa', value: 'box'},
];

const typeRegister = [
  {id: 1, text: 'Cliente', value: 'customer'},
  {id: 2, text: 'Fornecedor', value: 'provider'},
  {id: 3, text: 'Vendedor', value: 'seller'},
  {id: 4, text: 'Colaborador', value: 'collaborator' },
  {id: 4, text: 'Funcionário', value: 'employee' },
  {id: 5, text: 'Outros', value: 'others' },
];

const typePerson = [
  {id: 1, text: 'Física', value: 'F'},
  {id: 2, text: 'Jurídica', value: 'J'},
];

const typeYesNo = [
  {id: 1, text: 'Sim', value: 'yes'},
  {id: 2, text: 'Não', value: 'no'}
];

const typeSituation = [
  {id: 1, text: 'Ativo', value: 'active'},
  {id: 2, text: 'Desativado', value: 'disabled'}
];

const typeAllTypes = [
  {id: 1, text: 'Categoria', value: 'category'},
  {id: 2, text: 'Sub Categoria', value: 'sub-category'},
  {id: 3, text: 'Forma de Pagamento', value: 'payment-method'},
  {id: 4, text: 'Bandeira', value: 'card-flag'},
];

const UF = [
  {id: 1, text: 'AC', value: 'AC' },
  {id: 2, text: 'AL', value: 'AL' },
  {id: 3, text: 'AP', value: 'AP' },
  {id: 4, text: 'AM', value: 'AM' },
  {id: 5, text: 'BA', value: 'BA' },
  {id: 6, text: 'CE', value: 'CE' },
  {id: 7, text: 'DF', value: 'DF' },
  {id: 8, text: 'ES', value: 'ES' },
  {id: 9, text: 'GO', value: 'GO' },
  {id: 10, text: 'MA', value: 'MA' },
  {id: 11, text: 'MT', value: 'MT' },
  {id: 12, text: 'MS', value: 'MS' },
  {id: 13, text: 'MG', value: 'MG' },
  {id: 14, text: 'PA', value: 'PA' },
  {id: 15, text: 'PB', value: 'PB' },
  {id: 16, text: 'PR', value: 'PR' },
  {id: 17, text: 'PE', value: 'PE' },
  {id: 18, text: 'PI', value: 'PI' },
  {id: 19, text: 'RJ', value: 'RJ' },
  {id: 20, text: 'RN', value: 'RN' },
  {id: 21, text: 'RS', value: 'RS' },
  {id: 22, text: 'RO', value: 'RO' },
  {id: 23, text: 'RR', value: 'RR' },
  {id: 24, text: 'SC', value: 'SC' },
  {id: 25, text: 'SP', value: 'SP' },
  {id: 26, text: 'SE', value: 'SE' },
  {id: 27, text: 'TO', value: 'TO' },
  {id: 28, text: 'EX', value: 'EX' }
];

const typeAccount = [
  {id: 1, text: 'Corrente', value: 'current' },
  {id: 2, text: 'Poupança', value: 'savings' }
];

const typeBankNumber = [
  {id: 1, text: '341 ITAÚ UNIBANCO S.A', value: '341' },
  {id: 2, text: '237 BRADESCO S.A', value: '237' },
  {id: 3, text: '001 BANCO DO BRASIL S.A (BB)', value: '001' },
  {id: 4, text: '033 BANCO SANTANDER BRASIL S.A', value: '033' },
  {id: 5, text: '104 CAIXA ECONÔMICA FEDERAL (CEF)', value: '104' },
  {id: 6, text: '748 SICREDI S.A', value: '748' },
  {id: 7, text: '269 HSBC BANCO DE INVESTIMENTO', value: '269' },
  {id: 8, text: '260 NU PAGAMENTOS S.A (NUBANK)', value: '260' },
  {id: 9, text: '290 Pagseguro Internet S.A (PagBank)', value: '290' },
  {id: 10, text: '380 PicPay Servicos S.A.', value: '380' },
  {id: 11, text: '323 Mercado Pago - conta do Mercado Livre', value: '323' },
  {id: 12, text: '756 BANCOOB (BANCO COOPERATIVO DO BRASIL)', value: '756' },
  {id: 13, text: '335 Banco Digio S.A', value: '335' },
  // {id: 14, text: '237 NEXT BANK (UTILIZAR O MESMO CÓDIGO DO BRADESCO)', value: '237' },
  {id: 15, text: '637 BANCO SOFISA S.A (SOFISA DIRETO)', value: '637' },
  {id: 16, text: '077 BANCO INTER S.A', value: '077' },
  {id: 17, text: '212 BANCO ORIGINAL S.A', value: '212' },
  {id: 18, text: '655 BANCO VOTORANTIM S.A', value: '655' },
  {id: 19, text: '655 NEON PAGAMENTOS S.A (OS MESMOS DADOS DO BANCO VOTORANTIM)', value: '655' },
  {id: 20, text: '041 BANRISUL – BANCO DO ESTADO DO RIO GRANDE DO SUL S.A', value: '041' },
  {id: 21, text: '389 BANCO MERCANTIL DO BRASIL S.A', value: '389' },
  {id: 22, text: '422 BANCO SAFRA S.A', value: '422' },
  {id: 23, text: '070 BANCO DE BRASÍLIA (BRB)', value: '070' },
  {id: 24, text: '136 UNICRED COOPERATIVA', value: '136' },
  {id: 25, text: '741 BANCO RIBEIRÃO PRETO', value: '741' },
  {id: 26, text: '739 BANCO CETELEM S.A', value: '739' },
  {id: 27, text: '743 BANCO SEMEAR S.A', value: '743' },
  {id: 28, text: '100 PLANNER CORRETORA DE VALORES S.A', value: '100' },
  {id: 29, text: '096 BANCO B3 S.A', value: '096' },
  {id: 30, text: '747 Banco RABOBANK INTERNACIONAL DO BRASIL S.A', value: '747' },
  {id: 31, text: '752 BNP PARIBAS BRASIL S.A', value: '752' },
  {id: 32, text: '091 UNICRED CENTRAL RS', value: '091' },
  {id: 33, text: '399 KIRTON BANK', value: '399' },
  {id: 34, text: '108 PORTOCRED S.A', value: '108' },
  {id: 35, text: '757 BANCO KEB HANA DO BRASIL S.A', value: '757' },
  {id: 36, text: '102 XP INVESTIMENTOS S.A', value: '102' },
  {id: 37, text: '348 BANCO XP S/A', value: '348' },
  {id: 38, text: '340 SUPER PAGAMENTOS S/A (SUPERDITAL)', value: '340' },
  {id: 39, text: '364 GERENCIANET PAGAMENTOS DO BRASIL', value: '364' },
  {id: 40, text: '084 UNIPRIME NORTE DO PARANÁ', value: '084' },
  {id: 41, text: '180 CM CAPITAL MARKETS CCTVM LTDA', value: '180' },
  {id: 42, text: '066 BANCO MORGAN STANLEY S.A', value: '066' },
  {id: 43, text: '015 UBS BRASIL CCTVM S.A', value: '015' },
  {id: 44, text: '143 TREVISO CC S.A', value: '143' },
  {id: 45, text: '062 HIPERCARD BM S.A', value: '062' },
  {id: 46, text: '074 BCO. J.SAFRA S.A', value: '074' },
  {id: 47, text: '099 UNIPRIME CENTRAL CCC LTDA', value: '099' },
  {id: 48, text: '025 BANCO ALFA S.A.', value: '025' },
  {id: 49, text: '075 BCO ABN AMRO S.A', value: '075' },
  {id: 50, text: '040 BANCO CARGILL S.A', value: '040' },
  {id: 51, text: '190 SERVICOOP', value: '190' },
  {id: 52, text: '063 BANCO BRADESCARD', value: '063' },
  {id: 53, text: '191 NOVA FUTURA CTVM LTDA', value: '191' },
  {id: 54, text: '064 GOLDMAN SACHS DO BRASIL BM S.A', value: '064' },
  {id: 55, text: '097 CCC NOROESTE BRASILEIRO LTDA', value: '097' },
  {id: 56, text: '016 CCM DESP TRÂNS SC E RS', value: '016' },
  {id: 57, text: '012 BANCO INBURSA', value: '012' },
  {id: 58, text: '003 BANCO DA AMAZONIA S.A', value: '003' },
  {id: 59, text: '060 CONFIDENCE CC S.A', value: '060' },
  {id: 60, text: '037 BANCO DO ESTADO DO PARÁ S.A', value: '037' },
  {id: 61, text: '159 CASA CREDITO S.A', value: '159' },
  {id: 62, text: '172 ALBATROSS CCV S.A', value: '172' },
  {id: 63, text: '085 COOP CENTRAL AILOS', value: '085' },
  {id: 64, text: '114 CENTRAL COOPERATIVA DE CRÉDITO DO ESPÍRITO SANTO', value: '114' },
  {id: 65, text: '036 BANCO BBI S.A', value: '036' },
  {id: 66, text: '394 BANCO BRADESCO FINANCIAMENTOS S.A', value: '394' },
  {id: 67, text: '004 BANCO DO NORDESTE DO BRASIL S.A.', value: '004' },
  {id: 68, text: '320 BANCO CCB BRASIL S.A', value: '320' },
  {id: 69, text: '189 HS FINANCEIRA', value: '189' },
  {id: 70, text: '105 LECCA CFI S.A', value: '105' },
  {id: 71, text: '076 BANCO KDB BRASIL S.A.', value: '076' },
  {id: 72, text: '082 BANCO TOPÁZIO S.A', value: '082' },
  {id: 73, text: '286 CCR DE OURO', value: '286' },
  {id: 74, text: '093 PÓLOCRED SCMEPP LTDA', value: '093' },
  {id: 75, text: '273 CCR DE SÃO MIGUEL DO OESTE', value: '273' },
  {id: 76, text: '157 ICAP DO BRASIL CTVM LTDA', value: '157' },
  {id: 77, text: '183 SOCRED S.A', value: '183' },
  {id: 78, text: '014 NATIXIS BRASIL S.A', value: '014' },
  {id: 79, text: '130 CARUANA SCFI', value: '130' },
  {id: 80, text: '127 CODEPE CVC S.A', value: '127' },
  {id: 81, text: '079 BANCO ORIGINAL DO AGRONEGÓCIO S.A', value: '079' },
  {id: 82, text: '081 BBN BANCO BRASILEIRO DE NEGOCIOS S.A', value: '081' },
  {id: 83, text: '118 STANDARD CHARTERED BI S.A', value: '118' },
  {id: 84, text: '133 CRESOL CONFEDERAÇÃO', value: '133' },
  {id: 85, text: '121 BANCO AGIBANK S.A', value: '121' },
  {id: 86, text: '083 BANCO DA CHINA BRASIL S.A', value: '083' },
  {id: 87, text: '138 GET MONEY CC LTDA', value: '138' },
  {id: 88, text: '024 BCO BANDEPE S.A', value: '024' },
  {id: 89, text: '095 BANCO CONFIDENCE DE CÂMBIO S.A', value: '095' },
  {id: 90, text: '094 BANCO FINAXIS', value: '094' },
  {id: 91, text: '276 SENFF S.A', value: '276' },
  {id: 92, text: '137 MULTIMONEY CC LTDA', value: '137' },
  {id: 93, text: '092 BRK S.A', value: '092' },
  {id: 94, text: '047 BANCO BCO DO ESTADO DE SERGIPE S.A', value: '047' },
  {id: 95, text: '144 BEXS BANCO DE CAMBIO S.A.', value: '144' },
  {id: 96, text: '126 BR PARTNERS BI', value: '126' },
  {id: 97, text: '301 BPP INSTITUIÇÃO DE PAGAMENTOS S.A', value: '301' },
  {id: 98, text: '173 BRL TRUST DTVM SA', value: '173' },
  {id: 99, text: '119 BANCO WESTERN UNION', value: '119' },
  {id: 100, text: '254 PARANA BANCO S.A', value: '254' },
  {id: 101, text: '268 BARIGUI CH', value: '268' },
  {id: 102, text: '107 BANCO BOCOM BBM S.A', value: '107' },
  {id: 103, text: '412 BANCO CAPITAL S.A', value: '412' },
  {id: 104, text: '124 BANCO WOORI BANK DO BRASIL S.A', value: '124' },
  {id: 105, text: '149 FACTA S.A. CFI', value: '149' },
  {id: 106, text: '197 STONE PAGAMENTOS S.A', value: '197' },
  {id: 107, text: '142 BROKER BRASIL CC LTDA', value: '142' },
  {id: 108, text: '389 BANCO MERCANTIL DO BRASIL S.A.', value: '389' },
  {id: 109, text: '184 BANCO ITAÚ BBA S.A', value: '184' },
  {id: 110, text: '634 BANCO TRIANGULO S.A (BANCO TRIÂNGULO)', value: '634' },
  {id: 111, text: '545 SENSO CCVM S.A', value: '545' },
  {id: 112, text: '132 ICBC DO BRASIL BM S.A', value: '132' },
  {id: 113, text: '298 VIPS CC LTDA', value: '298' },
  {id: 114, text: '129 UBS BRASIL BI S.A', value: '129' },
  {id: 115, text: '128 MS BANK S.A BANCO DE CÂMBIO', value: '128' },
  {id: 116, text: '194 PARMETAL DTVM LTDA', value: '194' },
  {id: 117, text: '310 VORTX DTVM LTDA', value: '310' },
  {id: 118, text: '163  COMMERZBANK BRASIL S.A BANCO MÚLTIPLO', value: '163' },
  {id: 119, text: '280 AVISTA S.A', value: '280' },
  {id: 120, text: '146 GUITTA CC LTDA', value: '146' },
  {id: 121, text: '279 CCR DE PRIMAVERA DO LESTE', value: '279' },
  {id: 122, text: '182 DACASA FINANCEIRA S/A', value: '182' },
  {id: 123, text: '278 GENIAL INVESTIMENTOS CVM S.A', value: '278' },
  {id: 124, text: '271 IB CCTVM LTDA', value: '271' },
  {id: 125, text: '021 BANCO BANESTES S.A', value: '021' },
  {id: 126, text: '246 BANCO ABC BRASIL S.A', value: '246' },
  {id: 127, text: '751 SCOTIABANK BRASIL', value: '751' },
  {id: 128, text: '208 BANCO BTG PACTUAL S.A', value: '208' },
  {id: 129, text: '746 BANCO MODAL S.A', value: '746' },
  {id: 130, text: '241 BANCO CLASSICO S.A', value: '241' },
  {id: 131, text: '612 BANCO GUANABARA S.A', value: '612' },
  {id: 132, text: '604 BANCO INDUSTRIAL DO BRASIL S.A', value: '604' },
  {id: 133, text: '505 BANCO CREDIT SUISSE (BRL) S.A', value: '505' },
  {id: 134, text: '196 BANCO FAIR CC S.A', value: '196' },
  {id: 135, text: '300 BANCO LA NACION ARGENTINA', value: '300' },
  {id: 136, text: '477 CITIBANK N.A', value: '477' },
  {id: 137, text: '266 BANCO CEDULA S.A', value: '266' },
  {id: 138, text: '122 BANCO BRADESCO BERJ S.A', value: '122' },
  {id: 139, text: '376 BANCO J.P. MORGAN S.A', value: '376' },
  {id: 140, text: '473 BANCO CAIXA GERAL BRASIL S.A', value: '473' },
  {id: 141, text: '745 BANCO CITIBANK S.A', value: '745' },
  {id: 142, text: '120 BANCO RODOBENS S.A', value: '120' },
  {id: 143, text: '265 BANCO FATOR S.A', value: '265' },
  {id: 144, text: '007 BNDES (Banco Nacional do Desenvolvimento Social)', value: '007' },
  {id: 145, text: '188 ATIVA S.A INVESTIMENTOS', value: '188' },
  {id: 146, text: '134 BGC LIQUIDEZ DTVM LTDA', value: '134' },
  {id: 147, text: '641 BANCO ALVORADA S.A', value: '641' },
  {id: 148, text: '029 BANCO ITAÚ CONSIGNADO S.A', value: '029' },
  {id: 149, text: '243 BANCO MÁXIMA S.A', value: '243' },
  {id: 150, text: '078 HAITONG BI DO BRASIL S.A', value: '078' },
  {id: 151, text: '111 BANCO OLIVEIRA TRUST DTVM S.A', value: '111' },
  {id: 152, text: '017 BNY MELLON BANCO S.A', value: '017' },
  {id: 153, text: '174 PERNAMBUCANAS FINANC S.A', value: '174' },
  {id: 154, text: '495 LA PROVINCIA BUENOS AIRES BANCO', value: '495' },
  {id: 155, text: '125 BRASIL PLURAL S.A BANCO', value: '125' },
  {id: 156, text: '488 JPMORGAN CHASE BANK', value: '488' },
  {id: 157, text: '065 BANCO ANDBANK S.A', value: '065' },
  {id: 158, text: '492 ING BANK N.V', value: '492' },
  {id: 159, text: '250 BANCO BCV', value: '250' },
  {id: 160, text: '145 LEVYCAM CCV LTDA', value: '145' },
  {id: 161, text: '494 BANCO REP ORIENTAL URUGUAY', value: '494' },
  {id: 162, text: '253 BEXS CC S.A', value: '253' },
  {id: 163, text: '213 BCO ARBI S.A', value: '213' },
  {id: 164, text: '139 INTESA SANPAOLO BRASIL S.A', value: '139' },
  {id: 165, text: '018 BANCO TRICURY S.A', value: '018' },
  {id: 166, text: '630 BANCO INTERCAP S.A', value: '630' },
  {id: 167, text: '224 BANCO FIBRA S.A', value: '224' },
  {id: 168, text: '600 BANCO LUSO BRASILEIRO S.A', value: '600' },
  {id: 169, text: '623 BANCO PAN', value: '623' },
  {id: 170, text: '204 BANCO BRADESCO CARTOES S.A', value: '204' },
  {id: 171, text: '479 BANCO ITAUBANK S.A', value: '479' },
  {id: 172, text: '456 BANCO MUFG BRASIL S.A', value: '456' },
  {id: 173, text: '464 BANCO SUMITOMO MITSUI BRASIL S.A', value: '464' },
  {id: 174, text: '613 OMNI BANCO S.A', value: '613' },
  {id: 175, text: '652 ITAÚ UNIBANCO HOLDING BM S.A', value: '652' },
  {id: 176, text: '653 BANCO INDUSVAL S.A', value: '653' },
  {id: 177, text: '069 BANCO CREFISA S.A', value: '069' },
  {id: 178, text: '370 BANCO MIZUHO S.A', value: '370' },
  {id: 179, text: '249 BANCO INVESTCRED UNIBANCO S.A', value: '249' },
  {id: 180, text: '318 BANCO BMG S.A', value: '318' },
  {id: 181, text: '626 BANCO FICSA S.A', value: '626' },
  {id: 182, text: '270 SAGITUR CC LTDA', value: '270' },
  {id: 183, text: '366 BANCO SOCIETE GENERALE BRASIL', value: '366' },
  {id: 184, text: '113 MAGLIANO S.A', value: '113' },
  {id: 185, text: '131 TULLETT PREBON BRASIL CVC LTDA', value: '131' },
  {id: 186, text: '011 C.SUISSE HEDGING-GRIFFO CV S.A (Credit Suisse)', value: '011' },
  {id: 187, text: '611 BANCO PAULISTA', value: '611' },
  {id: 188, text: '755 BOFA MERRILL LYNCH BM S.A', value: '755' },
  {id: 189, text: '089 CCR REG MOGIANA', value: '089' },
  {id: 190, text: '643 BANCO PINE S.A', value: '643' },
  {id: 191, text: '140 EASYNVEST - TÍTULO CV S.A', value: '140' },
  {id: 192, text: '707 BANCO DAYCOVAL S.A', value: '707' },
  {id: 193, text: '288 CAROL DTVM LTDA', value: '288' },
  {id: 194, text: '101 RENASCENCA DTVM LTDA', value: '101' },
  {id: 195, text: '487 DEUTSCHE BANK S.A BANCO ALEMÃO', value: '487' },
  {id: 196, text: '233 BANCO CIFRA', value: '233' },
  {id: 197, text: '177 GUIDE', value: '177' },
  {id: 198, text: '633 BANCO RENDIMENTO S.A', value: '633' },
  {id: 199, text: '218 BANCO BS2 S.A', value: '218' },
  {id: 201, text: '292 BS2 DISTRIBUIDORA DE TÍTULOS E INVESTIMENTOS', value: '292' },
  {id: 202, text: '169 BANCO OLÉ BONSUCESSO CONSIGNADO S.A', value: '169' },
  {id: 203, text: '293 LASTRO RDV DTVM LTDA', value: '293' },
  {id: 204, text: '285 FRENTE CC LTDA', value: '285' },
  {id: 205, text: '080 B&T CC LTDA', value: '080' },
  {id: 206, text: '753 NOVO BANCO CONTINENTAL S.A BM', value: '753' },
  {id: 207, text: '222 BANCO CRÉDIT AGRICOLE BR S.A', value: '222' },
  {id: 208, text: '754 BANCO SISTEMA', value: '754' },
  {id: 209, text: '098 CREDIALIANÇA CCR', value: '098' },
  {id: 210, text: '610 BANCO VR S.A', value: '610' },
  {id: 211, text: '712 BANCO OURINVEST S.A', value: '712' },
  {id: 212, text: '010 CREDICOAMO', value: '010' },
  {id: 213, text: '283 RB CAPITAL INVESTIMENTOS DTVM LTDA', value: '283' },
  {id: 214, text: '217 BANCO JOHN DEERE S.A', value: '217' },
  {id: 215, text: '117 ADVANCED CC LTDA', value: '117' },
  {id: 216, text: '336 BANCO C6 S.A - C6 BANK', value: '336' },
  {id: 217, text: '654 BANCO DIGIMAIS S.A', value: '654' },

];

const typeInputOutput = [
  {id: 1, text: 'Entrada', value: 'input' },
  {id: 2, text: 'Saída', value: 'output' }
];

const statusBoxes = [
  { id: 1, text: 'Aberto', value: 'open' },
  { id: 2, text: 'Fechado', value: 'closed' },
];

const originTypeBoxMovements = [
  { id: 1, text: 'Manual', value: null },
  { id: 2, text: 'Ordem de Serviço', value: 'App\\Models\\OrderService' }
];

const originTypeBankMovements = [
  { id: 1, text: 'Manual', value: null },
  { id: 2, text: 'Ordem de Serviço', value: 'App\\Models\\OrderService' }
];

const typeBankMovements = [
  { id: 1, text: 'Pix', value: 'pix' },
  { id: 2, text: 'Transferência', value: 'tranfer' },
  { id: 3, text: 'Depósito Espécie', value: 'cash' },
  { id: 4, text: 'Depósito Cheque', value: 'check' },
  { id: 5, text: 'Saque', value: 'withdraw' }
];

const typeStatusAccountPayments = [
  { id: 1, text: 'Pendente', value: 'pending' },
  { id: 2, text: 'Parcial', value: 'partial' },
  { id: 3, text: 'Quitado', value: 'settled' },
];

const statusInvoices = [
  {id: 1, text: 'Pendente', value: 'pending'},
  {id: 2, text: 'Autorizado', value: 'authorized'},
  {id: 3, text: 'Rejeitada', value: 'rejected'},
  {id: 3, text: 'Processando', value: 'processing'},
];

const statusOrderSales = [
  {id: 1, text: 'Pendente', value: 'pending'},
  {id: 2, text: 'Entregue', value: 'delivered'},
  {id: 3, text: 'Cancelado', value: 'canceled'},
];

export { 
  statusAppointment,
  statusOrderService,
  typeUser,
  typePerson,
  typeRegister,
  typeYesNo,
  typeSituation,
  typeAllTypes,
  UF,
  typeAccount,
  typeBankNumber,
  typeInputOutput,
  statusBoxes,
  originTypeBoxMovements,
  originTypeBankMovements,
  typeBankMovements,
  typeStatusAccountPayments,
  statusInvoices,
  statusOrderSales
};